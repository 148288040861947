import React, { useEffect, memo } from 'react'

import { saleURL } from '../Utilities/const'
import closeBtn from './../../assets/img/exit.png';


export const Login = memo((props) => {
  useEffect(() => {
    props.setUniqKey(localStorage.getItem('key'));
  }, [])
  return (
    <div className="login-wrapper">
      <form
        onSubmit={(e) => props.handleLogin(e)}
        className="login-wrapper__form">
        <p className="text-black text-semi-bold ">aktywuj konto premium</p>
        <input
          onChange={(e) => props.handleChangeUniqKey(e)}
          value={props.valueUniqKey}
          className="login-wrapper__input"
          type="text"
          placeholder="WPROWADŹ KOD AKTYWACYJNY" />
        <button className="login-wrapper__btn " type="submit">Zaloguj się</button>
      </form>
      <div className=" login-wrapper__access">
        <a href={saleURL} className="login-wrapper__btn login-wrapper__btn--left text-medium text-semi-bold  text-white text-left">
          Kup konto premium
                </a>
      </div>
      <div className="App__close-btn App__close-btn--login" onClick={() => {
        props.showLayerBottom();
        props.turnOffLayer();
      }}>
        <img src={closeBtn} alt="" />
      </div>
    </div>
  )
}
)