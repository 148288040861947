import React, { memo, useState } from 'react'

import itemImag from './../../assets/img/szukaj_osoby.png';
import addItem from './../../assets/img/dodaj_email.png';
import removeItem from './../../assets/img/usun_email.png'
import penImg from '../../assets/img/pen.png'

import styled, { css, keyframes } from 'styled-components'

import { connect } from 'react-redux'

const slideIn = keyframes`
  0%{
    max-height: 0px;
  }
  100%{
    max-height: 200px;
    padding: 10px 20px;
  }
`;
const slideOut = keyframes`
  0%{
    max-height: 200px;
    padding: 10px 20px;
  }
  100%{
    max-height: 0px;
    padding: 0px 20px;
  }
`;

const ItemWrapper = styled.div`
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin: 5px 10px;
`;
const EditField = styled.div`
  color: white;
  font-size: 11px;
  background: #11C4D7;
  width: 100%;
  display: flex;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  padding: 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ shouldSlideIn }) => shouldSlideIn === true ? css`
  max-height: 300px;
    padding: 10px 20px;
    ` : shouldSlideIn === false ? css`
    max-height: 0px;
    padding: 0px 10px;
  ` : null}
  @media (max-width: 768px) {
      font-size: 9px;
  }
`;

const ResultsItem = memo((props) => {

  const [icon, setIcon] = useState(itemImag)
  const [shouldSlideIn, setShouldSlideIn] = useState(undefined)

  let text = props.data.checked ? 'usuń osobę z' : 'dodaj osobę do';
  let imgUrl = props.data.checked ? removeItem : addItem;

  return (
    <ItemWrapper style={{ marginTop: props.index === 0 ? '10px' : '5px', marginBottom: props.index === props.length - 1 ? '60px' : '5px' }}>
      <div className="results-item" style={{ borderRadius: 0, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', marginBottom: '0px', boxShadow: 'none', marginTop: '0px' }}>
        <div className="results-item__info">
          <div className="results-item__circle"
            onMouseEnter={() => {
              if (props.link) {
                if (shouldSlideIn === false || shouldSlideIn === undefined) {
                  setIcon(penImg)
                }
              }
            }} onMouseLeave={() => {
              if (props.link) {
                if (shouldSlideIn === false || shouldSlideIn === undefined) {
                  setIcon(itemImag)
                }
              }
            }} style={{ cursor: 'pointer' }} onClick={() => {
              if (props.link) {
                setShouldSlideIn(shouldSlideIn => !shouldSlideIn);
                if (!shouldSlideIn) {
                  setIcon(penImg);
                } else {
                  setIcon(itemImag)
                }
              }
            }}
          >
            <img src={icon} alt="" />
          </div>
          <div>
            <p className="results-item__details--name text-dark text-bold">{props.data.name} {props.data.position}</p>
            <p className="text-bold">{props.data.email}</p>
          </div>
        </div>
        {parseInt(props.sentSuccessfullyEmails) === 0 && props.movedMessages === 0 && props.emailsOpenedAmount === 0 ? <div className="results-item__action text-right">
          <p>{text}<br />kampanii </p>
          <img
            onClick={() => { props.toggleItemToCampaign(props.data.email, props.index, props.data.checked) }}
            className={`results-item__action-img  ${!props.data.checked ? 'results-item__action-img--disable' : 'results-item__action-img--active'}`}
            src={imgUrl} alt="" />
        </div> : null
        }
      </div >
      {props.link &&
        <EditField shouldSlideIn={shouldSlideIn}>
          <p style={{ fontWeight: 'bold' }}>ŹRÓDŁO: <a style={{ color: 'white', fontWeight: 'normal', textDecoration: 'none' }} href={props.link} target="_blank" rel="noopener noreferrer">{props.link}</a></p>
        </EditField>}
    </ItemWrapper>
  )
})

const mapStateToProps = state => {
  return {
    emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    emailsOpenedAmount: state.emailsOpenedAmount,
    movedMessages: state.movedMessages
  }
}

export default connect(mapStateToProps, null)(ResultsItem)
