import React, { useEffect, useState, memo } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'

import cancel from '../../assets/img/exit-white.png'

const ImportExportModalWrapper = styled.div`
    width: 45%;
    height: 45%;
    background: rgba(17,196,215, 1);
    filter:drop-shadow(0px 0px 3px black);
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    border-radius: 15px;
    @media (max-width: 600px) {
        width: 60%;
        height: 40%;
    }
`;

const StyledButton = styled.div`
    padding: 15px 15px;
    margin: 10px 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    white-space: nowrap;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid white;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        border: 2.5px solid white;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        padding: 11px 11px;
    }
`;

const StyledImage = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    width: 50px;
    height: 50px;
    z-index: 9999;

    @media (max-width: 768px) {
        height: 35px;   
        width: 35px;
    }
`;

const StyledInputFile = styled.input`
   	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;
    background: none;
`;

const StyledLabel = styled.label`
     padding: 15px 15px;
    margin: 10px 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid white;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        border: 2.5px solid white;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        padding: 11px 11px;
    }
`;
const ButtonsWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const ButtonsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;
const StyledError = styled.div`
    color: white;
    font-size: 13px;
    margin-top: 10px;
    max-width: 60%;
`;

const ImportExportModal = memo((props) => {
    const [file, setFile] = useState();
    const handleFile = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            props.setFile(e.target.files[0]);
        }
    }
    useEffect(() => {
        if (file) {
            props.makeImport();
            props.toggleImportExport();
            setFile("");
        }
    }, [file])
    return (
        <ImportExportModalWrapper className="importexport">
            <StyledImage onClick={props.toggleImportExport} src={cancel}></StyledImage>
            <StyledInputFile onChange={(e) => handleFile(e)} type="file" name="import" id="file" />
            <ButtonsWrapper>
                <ButtonsContent>
                    <StyledButton onClick={() => {
                        props.export();
                        props.toggleImportExport();
                    }}>Eksport e-maili</StyledButton>
                    <StyledLabel style={(props.emailsSuccesfullySentAmount === 0 && props.emailsOpenedAmount === 0) ? null : { opacity: '0.7', cursor: 'not-allowed', pointerEvents: 'none' }} htmlFor="file">Import e-maili</StyledLabel>
                </ButtonsContent>
                {props.importError && <StyledError>{props.importError}</StyledError>}
            </ButtonsWrapper>
        </ImportExportModalWrapper>
    )
})

const mapStateToProps = state => {
    return {
        emailsOpenedAmount: state.emailsOpenedAmount,
        emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    }
}

export default connect(mapStateToProps, null)(ImportExportModal)
