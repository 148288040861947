import React, { memo } from 'react'

import { Chart } from '../Utilities/Chart'
import closeBtn from './../../assets/img/exit.png';


export const UserProfile = memo((props) => {
  return (
    <div className="user-wrapper">
      <div className="user-header">
        <div className="App__close-btn App__close-btn--login" onClick={() => {
          props.showLayerBottom();
          props.turnOffLayer();
          props.updateCharts();
        }}>
          <img src={closeBtn} alt="" />
        </div>
        {/*   <button className="user-header__btn user-header__btn--right text-medium">
          Doładuj konto
                </button> */}
        <div className="user-header__details">
          <p className=" text-medium">{props.user}</p>
        </div>
        <button
          className="user-header__btn user-header__btn--left text-medium"
          onClick={props.logOut}
        >
          Wyloguj
                </button>
      </div>
      <div className="charts-wrapper">
        {props.charts.map((chart, index) => {
          return (
            <Chart key={index} data={chart} />
          )
        })}
      </div>
    </div>
  )
})
