import React, { Component } from 'react'
import ResultsItem from './ResultsItem';
import uuid from 'uuid'

class ResultsItems extends React.PureComponent {
  render() {
    return (
      <div className="results-search">
        <div className="results-search__wrapper-search">
          <div className="results-search__summary">
            <span className="results-search__prefix"></span>
            <p className="text-dark">Firma: <strong>{this.props.results.searchCompany}</strong></p>
            <p className="text-dark">WWW: <strong>{this.props.results.wwwAdress}</strong></p>
            <div className="results-search__count-details">
              {this.props.results.list && <p className=" text-bold"><span>{this.props.results.list.length}</span> weryfikowanych adresów e-mail</p>}
              <p className=" text-bold"><span>{this.props.contacts}</span> adresów dodanych do kampanii</p>
            </div>
          </div>
          <div className="results-search__list" style={{ display: 'flex', flexDirection: 'column' }}>
            {this.props.results.list.map((element, index) => {
              return <ResultsItem
                length={this.props.results.list.length}
                key={element.email}
                link={this.props.results.list[index].link}
                index={index}
                data={element}
                layer={this.props.layer}
                toggleItemToCampaign={this.props.toggleItemToCampaign}
                sentSuccessfullyEmails={this.props.sentSuccessfullyEmails}
              />
            })}
          </div>
        </div>
      </div>
    )
  }
}


export default ResultsItems;
