import React, { Component } from 'react';
import $ from 'jquery'
import TopLayer from './Layout/TopLayer';
import ResultsItems from './Results/ResultsItems';
import SearchSection from './Search/SearchSection';
import Modal from './Utilities/Modal';
import { Login } from './Layout/Login';
import { UserProfile } from './Layout/UserProfile';
import { Loader } from './Utilities/Loader';
import { Cookies } from 'react-cookie';
import { textModalSale } from './Utilities/const'
import StickySliders from './StickySliders/StickySliders'
import TipBar from './TipBar/TipBar'
import profilImg from './../assets/img/profl.png'
import { connect } from 'react-redux'
import { setTip, setHref, setBlockFirstPatterns, setBlockSecondPatterns, setCurrentItem, setEmailsOpenedAmount, setEmailsWantedToSendAmount, setEmailsSuccesfullySentAmount, setProgressBarValue, setMaxEmailsAmount, setStopInterval, setMovedMessages, setTabsData } from '../redux/reducer'
import StickySlider from './StickySliders/StickySlider'
import axios from 'axios'
import browserFingerPrint from 'fingerprintjs2';

const cookies = new Cookies();

let x = 0;

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.playerRef = React.createRef();

    this.state = {
      cookieKey: null,
      user: '',
      maxCountNumber: 0,
      settingsOptions: [
        {
          type: 'message',
          description: 'treść wiadomości'
        },
        {
          type: 'signature',
          description: 'podpis'
        },
        {
          type: 'settings',
          description: 'ustawienia e-mail'
        }
      ],
      charts: [],
      results: {},
      campaignItems: [],
      currentOptionForCloseButton: "dataPersonal",
      layerIsShowTop: false,
      isLoggedIn: false,
      layerIsShowBottom: false,
      isModalVisible: false,
      isShadowLayerVisible: false,
      isLoadingModalAction: false,
      valueUniqKey: '',
      modalCustomText: '',
      modalCustomTextLoading: '',
      isSending: false,
      currentNumberOfMail: 0,
      isVideoShow: true,
      activeAdd: false,
      sentSuccessfullyEmails: 0,
      emailsWantedToSend: 0,
      shouldSecondProgressBar: false,
      resetInterval: false,
      emailsOpened: 0,
      tip: "sss",
      shouldImportExportAppear: false,
      shouldSpamScoringAppearProOption: false,
      shouldAccountBePro: false
    }
  }

  updateCampaign = (items) => {
    this.setState({
      campaignItems: items
    })
  }

  deleteCampaign = () => {
    $('.progressBar').fadeOut();
    let userId = cookies.get('_id_user');
    this.props.setStopInterval(true);
    axios.post('https://contact-hunter.com/pl/clear_campaign.php', userId).then(response => {
      if (response) {
        localStorage.removeItem('campaignItems');
        localStorage.removeItem('deliveryTime');
        localStorage.removeItem('timeFor2Message');
        this.props.setEmailsOpenedAmount(0);
        this.props.setEmailsWantedToSendAmount(0);
        this.props.setEmailsSuccesfullySentAmount(0);
        this.props.setMovedMessages(0);
        this.setState({
          results: {},
          campaignItems: [],
          currentNumberOfMail: 0,
          sentSuccessfullyEmails: 0,
          emailsWantedToSend: 0,
          resetInterval: true,
          emailsOpened: 0
        });
        setTimeout(() => {
          this.props.setStopInterval(false);
        }, 6000);
        document.querySelector('body').classList.remove("page-block-scroll")
        $('.nav-header__btn-send_sendmail').attr('disabled', true);
      }
    });
  }

  _isMounted = false;

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLoggedIn === true && prevState.isLoggedIn === false) {
      axios.post('https://contact-hunter.com/pl/aktualne_kampanie.php', this.state.valueUniqKey).then(res => {
        if (res.data.list) {
          if (res.data.list.length === 0) {
            if (localStorage.getItem('campaignItems')) {
              this.props.setEmailsWantedToSendAmount(JSON.parse(localStorage.getItem('campaignItems')).length);
              if (JSON.parse(localStorage.getItem('campaignItems')).length === 0) {
                localStorage.removeItem('campaignItems');
                this.setState({
                  layerIsShowTop: false
                })
              }
              let savedCampaign = JSON.parse(localStorage.getItem('campaignItems'));
              if (savedCampaign) {
                this.setState({
                  campaignItems: savedCampaign,
                  currentNumberOfMail: savedCampaign.length,
                })
              }
              // if (this.props.emailsSuccesfullySentAmount === 0 && this.props.emailsOpenedAmount === 0) {
              //   this.setState({
              //     results: {
              //       list: savedCampaign
              //     }
              //   })
              // }
            }
          } else {
            if (res.data.list) localStorage.setItem('campaignItems', JSON.stringify(res.data.list));
            this.setState({
              campaignItems: res.data.list
            })
            if (res.data.text) localStorage.setItem('text1', JSON.stringify(res.data.text))
            if (res.data.text_second) localStorage.setItem('text2', JSON.stringify(res.data.text_second))
            if (res.data.title) localStorage.setItem('topic1', JSON.stringify(res.data.title))
            if (res.data.title_second) localStorage.setItem('topic2', JSON.stringify(res.data.title_second))
            if (res.data.podpis) localStorage.setItem('signature', res.data.podpis)
            this.props.setTabsData({
              firstMessage: res.data.text.replace(/<br ?\/?>/g, "\n"),
              firstMessageTitle: res.data.title.replace(/<br ?\/?>/g, "\n"),
              secondMessage: res.data.text_second.replace(/<br ?\/?>/g, "\n"),
              secondMessageTitle: res.data.title_second.replace(/<br ?\/?>/g, "\n")
            })
            // if (this.props.emailsSuccesfullySentAmount === 0 && this.props.emailsOpenedAmount === 0) {
            //   this.setState({
            //     results: {
            //       list: res.data.list
            //     }
            //   })
            // }
            this.props.setEmailsWantedToSendAmount(res.data.list.length);
            let moved = [];
            let sended = [];
            let opened = [];
            res.data.list.map(item => {
              if (item.status === "sended") sended.push(item);
              if (item.status === "opened") opened.push(item);
              if (item.status === "message_moved") moved.push(item);
            });
            this.props.setEmailsOpenedAmount(opened.length);
            this.props.setEmailsSuccesfullySentAmount(res.data.sendMessage);
            this.props.setMovedMessages(moved.length);
            if (this.props.emailsOpenedAmount > 0) {
              this.props.setProgressBarValue(Math.round((this.props.emailsOpenedAmount / this.props.emailsWantedToSendAmount) * 100));
            } else if (this.props.emailsSuccesfullySentAmount > 0) {
              this.props.setProgressBarValue(Math.round((this.props.emailsSuccesfullySentAmount / this.props.emailsWantedToSendAmount) * 100));
            } else if (this.props.emailsWantedToSendAmount > 0) {
              this.props.setProgressBarValue(Math.round((this.props.emailsWantedToSendAmount / this.props.maxEmailsAmount) * 100));
            }
          }
        }
      });
    }
  }

  componentDidMount() {

    // const emails = [];
    // for (let x = 0; x < 200; x++) {
    //   const email = {
    //     email: `sadas${x}wp.pl`,
    //     status: 'to_send',
    //     name: 'kuba',
    //     position: 'boss'
    //   }
    //   emails.push(email)
    // }

    // this.props.setEmailsWantedToSendAmount(emails.length)
    // this.props.setMaxEmailsAmount(50)

    // this.setState({
    //   campaignItems: emails
    // })

    if (this.props.emailsOpenedAmount > 0) {
      this.props.setProgressBarValue(Math.round((this.props.emailsOpenedAmount / this.props.emailsWantedToSendAmount) * 100));
    } else if (this.props.emailsSuccesfullySentAmount > 0) {
      this.props.setProgressBarValue(Math.round((this.props.emailsSuccesfullySentAmount / this.props.emailsWantedToSendAmount) * 100));
    }

    setTimeout(() => {
      browserFingerPrint.getV18((fingerprint, components) => {
        cookies.set('_browserFingerPrint', fingerprint);
      });
    }, 3000);

    axios.get('https://jsonip.com/').then(response => {
      cookies.set('_ip', response.data.ip);
    })

    axios.get('https://contact-hunter.com/pl/ilosc_wejsc.php').then(response => {
      this.setState({
        visitsOnPage: response.data
      })
    })

    if (cookies.get('_id_user')) {
      this.loginUser();
    }

    if (cookies.get('_user_video_show') === 'false') {
      this.setState({
        isVideoShow: false
      })
    }
    this._isMounted = true;

    if (!this.state.isLoggedIn) {
      setTimeout(() => {
        $('.premium-info').css('animation', 'slideInPremium 1.4s ease-in-out both');
      }, 5000);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateCookieCampaign() {
    cookies.set('_campaign_user_create', JSON.stringify(this.state.campaignItems));
  }

  createNewCampaign = () => {
    $('.progressBar').fadeOut();
    let userId = cookies.get('_id_user');
    this.props.setStopInterval(true);
    axios.post('https://contact-hunter.com/pl/clear_campaign.php', userId).then(response => {
      if (response) {
        localStorage.removeItem('text1');
        localStorage.removeItem('text2');
        localStorage.removeItem('topic1');
        localStorage.removeItem('topic2');
        localStorage.removeItem('campaignItems');
        localStorage.removeItem('deliveryTime');
        localStorage.removeItem('timeFor2Message');
        this.props.setTabsData({
          firstMessage: "",
          firstMessageTitle: "",
          secondMessage: "",
          secondMessageTitle: ""
        })
        this.props.setEmailsOpenedAmount(0);
        this.props.setEmailsWantedToSendAmount(0);
        this.props.setEmailsSuccesfullySentAmount(0);
        this.props.setMovedMessages(0);
        this.props.setBlockFirstPatterns(false);
        this.props.setBlockSecondPatterns(false);
        this.setState({
          results: {},
          campaignItems: [],
          layerIsShowTop: false,
          layerIsShowBottom: false,
          currentNumberOfMail: 0,
          sentSuccessfullyEmails: 0,
          emailsWantedToSend: 0,
          resetInterval: true,
          emailsOpened: 0
        });
        setTimeout(() => {
          this.props.setStopInterval(false);
        }, 6000);
        document.querySelector('body').classList.remove("page-block-scroll")
        $('.nav-header__btn-send_sendmail').attr('disabled', true);
      }
    });
  }
  handleChangeLoading(isShow, msg) {
    this.setState({
      isLoadingModalAction: isShow,
      isShadowLayerVisible: isShow,
      modalCustomTextLoading: msg
    })
  }

  showLayerTop() {
    this.setState(prevState => ({
      layerIsShowTop: !prevState.layerIsShowTop,
      layerIsShowBottom: false,
    }))

    if (this.state.layerIsShowTop) {
      document.querySelector('body').classList.remove("page-block-scroll")
    } else {
      document.querySelector('body').classList.add("page-block-scroll")
    }
  }

  showLayerBottom() {
    this.setState(prevState => ({
      layerIsShowBottom: !prevState.layerIsShowBottom,
      layerIsShowTop: false,
      isShadowLayerVisible: !this.state.isShadowLayerVisible
    }))
    document.querySelector('body').classList.remove("page-block-scroll");
  }

  checkValidKey(obj) {
    if (obj.is_logged) {
      this.setState({
        isLoggedIn: true,
      })

      cookies.set('_id_user', obj.key);

      return true;

    } else if (obj.is_expired) {

      this.handleShowCustomModal("Klucz wygasł", false)

      return false;

    } else if (obj.is_trial) {

      return true;

    } else if ((!obj.is_expired) && (!obj.is_logged)) {

      this.handleShowCustomModal("Błąd klucza", false)

      return false;
    }
  }

  getChartsData() {
    fetch('https://contact-hunter.com/pl/get_charts.php', {
      method: "POST"
    })
      .then(response => response.json())
      .then(response => {

        const [currentStatusKey, ...newChartsData] = [...response]

        if (this.checkValidKey(currentStatusKey)) {
          this.setState({
            charts: newChartsData
          })
        }
      })
      .catch(err => console.log(err))
  }

  updateStatusSendAction() {

    fetch('https://contact-hunter.com/pl/poziom_wysylki.php', {
      method: "GET"
    })
      .then(response => response.json())
      .then(response => {
        if (!response.is_finished) {
          setTimeout(() => {
            this.updateStatusSendAction();
          }, 25000)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  loginUser(e) {

    if (e) {
      e.preventDefault();

    }

    const data = {
      key: this.state.valueUniqKey,
    }

    fetch('https://contact-hunter.com/index_go.php', {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {

        this.setState({
          shouldImportExportAppear: response[0].is_import,
          // shouldSpamScoringAppearProOption: false,
          shouldSpamScoringAppearProOption: response[0].is_spam,
          shouldAccountBePro: response[0].is_pro
        })

        const [currentStatusKey, maxCountNumber] = [...response]

        if (this.checkValidKey(currentStatusKey)) {

          this.getChartsData();

          $('.footer-wrapper').css('animation', 'slideInFooter 2s ease-in-out both');

          setTimeout(() => {
            $('.footer-wrapper').css('animation', 'none');
          }, 3000);
          this.props.setMaxEmailsAmount(+maxCountNumber.limit_emails);
          this.setState({
            user: currentStatusKey.name_of_product,
            maxCountNumber: maxCountNumber.limit_emails,
            valueUniqKey: currentStatusKey.key,
          })

          let savedUniqKey = localStorage.getItem('key');

          if (this.props.emailsWantedToSendAmount > 0 && this.props.emailsSuccesfullySentAmount <= 0 && this.props.emailsOpenedAmount <= 0) {
            this.props.setProgressBarValue(Math.round((this.props.emailsWantedToSendAmount / +this.props.maxEmailsAmount) * 100));
          }
          if (savedUniqKey) {

            if (this.state.valueUniqKey !== savedUniqKey) {
              localStorage.removeItem('key');
              localStorage.setItem('key', this.state.valueUniqKey);
            }

          } else {
            localStorage.setItem('key', this.state.valueUniqKey);
          }

          let savedDate = localStorage.getItem('time');

          if (!savedDate) {

            let now = new Date().getDate();
            localStorage.setItem('time', JSON.stringify(now));

          } else {

            let resetTime = savedDate + 30;
            let now = new Date().getDate();

            if (now > resetTime) {
              localStorage.removeItem('key');
              localStorage.removeItem('time');
              let now = new Date().getDate();
              localStorage.setItem('time', JSON.stringify(now));
            }

          }

          const activeCampaign = cookies.get('_campaign_user_create') || null;

          if (activeCampaign) {
            this.setState({
              layerIsShowTop: false
            })

          } else if (currentStatusKey.is_campaign) {
            this.updateStatusSendAction()
          }
        }
      })
      .catch(err => console.log(err))
  }

  handleChangeUniqKey(e) {
    this.setState({
      valueUniqKey: e.target.value
    })
  }
  setUniqKey(e) {
    this.setState({
      valueUniqKey: e
    })
  }

  turnOffLayer = () => {
    this.setState({
      isShadowLayerVisible: false
    })
  }
  turnOnLayer = () => {
    this.setState({
      isShadowLayerVisible: true
    })
  }

  handleChangePassword(e) {
    this.setState({
      valuePassword: e.target.value
    })
  }

  handleChangeResultsSearch(obj) {

    if (obj.length === 0) return false;

    this.state.campaignItems.forEach(el => {
      obj.list.forEach(el2 => {
        if (el.email === el2.email) {
          el2.checked = true;
        }
      })
    })
    this.setState({
      results: obj
    })
  }

  logOutUser() {
    this.setState({
      isLoggedIn: false,
      layerIsShowBottom: false,
      isShadowLayerVisible: false,
      results: [],
      campaignItems: [],
      valueUniqKey: ''
    })
    cookies.remove('_id_user');
  }

  toggleItemToCampaign(email, index, checked) {

    if (!this.state.isLoggedIn) {
      this.handleShowCustomModal(textModalSale, true)
      return false;
    }

    if (checked) {

      this.removeItemFromCampaign(email, index);

    } else {

      if (this.state.campaignItems.length < this.state.maxCountNumber) {
        // let height = 110;
        // let empty = 0;
        // if (resultsList.list[index].surname === "" && resultsList.list[index].name === "") empty++;
        // if (resultsList.list[index].position === "") empty++;
        // if (resultsList.list[index].companyName === "") empty++;
        // if (empty === 0) height = 110;
        // if (empty === 1) height = 84;
        // if (empty === 2) height = 62;
        // if (empty === 3) height = 60;
        let resultsList = this.state.results;
        let campaignList = this.state.campaignItems;
        resultsList.list[index].checked = true;
        // resultsList.list[index].height = height;
        campaignList.push(resultsList.list[index]);
        if (localStorage.getItem('campaignItems')) {
          let array = JSON.parse(localStorage.getItem('campaignItems'));
          let connectedArray = array.concat(campaignList);
          function uniqueArray(arr, comp) {
            const unique = arr
              .map(e => e[comp])
              .map((e, i, final) => final.indexOf(e) === i && i)
              .filter(e => arr[e]).map(e => arr[e]);
            return unique;
          }
          localStorage.setItem('campaignItems', JSON.stringify(uniqueArray(connectedArray, 'email')));
        } else {
          localStorage.setItem('campaignItems', JSON.stringify(campaignList));
        }
        this.props.setEmailsWantedToSendAmount(campaignList.length);
        this.props.setProgressBarValue(Math.round((campaignList.length / +this.props.maxEmailsAmount) * 100));
        this.setState({
          results: resultsList,
          campaignItems: campaignList,
          currentNumberOfMail: campaignList.length
        });
      } else {
        this.handleShowCustomModal(`Osiągnięto limit adresów dla kampanii e-mailingowej. Korzystasz z pakietu obsługującego maksymalnie ${this.state.maxCountNumber} leadów na kampanię.`);
      }
    }

    setTimeout(() => {
      this.updateCookieCampaign()
    }, 100);

  }

  removeItemFromCampaign(email, index) {

    let campaignList = this.state.campaignItems;
    let resultsList = this.state.results;

    let updatedCampaignList = campaignList.filter(person => {
      return email !== person.email;
    });

    let deletedPerson = campaignList.filter(person => {
      return email === person.email
    });

    let deletedPersonIndex;

    if (resultsList.list) {
      deletedPersonIndex = resultsList.list.findIndex(x => x.email === deletedPerson[0].email);
      if (resultsList.list[deletedPersonIndex]) {
        resultsList.list[deletedPersonIndex].checked = false;
      }
    } else if (!resultsList.list && campaignList) {
      deletedPersonIndex = campaignList.findIndex(x => x.email === deletedPerson[0].email);
      if (campaignList[deletedPersonIndex]) {
        campaignList[deletedPersonIndex].checked = false;
      }
    }

    localStorage.setItem('campaignItems', JSON.stringify(updatedCampaignList));

    if (localStorage.getItem('campaignItems')) {
      let array = JSON.parse(localStorage.getItem('campaignItems'));
      let connectedArray = array.concat(updatedCampaignList);
      function uniqueArray(arr, comp) {
        const unique = arr
          .map(e => e[comp])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter(e => arr[e]).map(e => arr[e]);
        return unique;
      }
      localStorage.setItem('campaignItems', JSON.stringify(uniqueArray(connectedArray, 'email')));
    } else {
      localStorage.setItem('campaignItems', JSON.stringify(updatedCampaignList));
    }

    if (JSON.parse(localStorage.getItem('campaignItems')).length === 0) {
      localStorage.removeItem('campaignItems');
      this.props.setProgressBarValue(0);
      this.turnOffLayer();
      this.setState({
        layerIsShowTop: false
      })
    }

    this.props.setEmailsWantedToSendAmount(updatedCampaignList.length);
    this.props.setProgressBarValue(Math.round((updatedCampaignList.length / +this.props.maxEmailsAmount) * 100));

    this.setState({
      results: resultsList,
      campaignItems: updatedCampaignList,
      currentNumberOfMail: updatedCampaignList.length,
    })

    if (campaignList.length === 1) {
      document.querySelector('body').classList.remove("page-block-scroll");
      $('.progressBar').hide();
    }

    setTimeout(() => {
      this.updateCookieCampaign()
    }, 100);

  }

  getCurrentOption = (s) => {
    this.setState({
      currentOptionForCloseButton: s
    })
  }

  handleCloseModal() {
    this.setState({
      isModalVisible: false,
      isShadowLayerVisible: false,
      showLink: false
    })
  }

  handleShowCustomModal(msg, showAdvertisement) {
    this.setState({
      modalCustomText: msg,
      isModalVisible: true,
      isShadowLayerVisible: false,
      activeAdd: showAdvertisement
    })
  }

  importCampaign = (items) => {
    this.props.setEmailsWantedToSendAmount(items.length);
    this.props.setProgressBarValue(Math.round((items.length / +this.props.maxEmailsAmount) * 100));
    $('.progressBar').show();
    this.setState({
      campaignItems: items,
      // results: {
      //   list: items
      // },
      currentNumberOfMail: items.length
    })
  }

  renderCustomModal(customText) {

    if (!this.state.isModalVisible) return false;

    return (
      <Modal
        link={(customText.length > 150 && !this.state.isLoggedIn) ? true : false}
        handleCloseModal={() => this.handleCloseModal()}
        showBtn={!this.state.activeAdd}
        showLink={this.state.activeAdd}
      >
        <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: customText }} />
      </Modal>
    )
  }

  clearResults = () => {
    this.setState({
      results: []
    })
  }

  render() {
    return (
      <>
        {!this.state.isLoggedIn && <StickySliders visitsOnPage={this.state.visitsOnPage}></StickySliders>}
        {this.state.isLoggedIn && <StickySlider visitsOnPage={this.state.visitsOnPage}></StickySlider>}
        <div className="App">
          <TopLayer
            deleteCampaign={this.deleteCampaign}
            updateCharts={() => this.getChartsData()}
            shouldImportExportAppear={this.state.shouldImportExportAppear}
            shouldSpamScoringAppearProOption={this.state.shouldSpamScoringAppearProOption}
            shouldAccountBePro={this.state.shouldAccountBePro}
            clearResults={this.clearResults}
            currentOptionForCloseButton={this.state.currentOptionForCloseButton}
            turnOffLayer={this.turnOffLayer}
            updateCampaign={this.updateCampaign}
            isLogged={this.state.isLoggedIn}
            emailsOpened={this.state.emailsOpened}
            updateReceivedMessages={this.updateReceivedMessages}
            resetInterval={this.state.resetInterval}
            sentSuccessfullyEmails={this.state.sentSuccessfullyEmails}
            importCampaign={this.importCampaign}
            emailsWantedToSend={this.state.emailsWantedToSend}
            layerIsShowTop={this.state.layerIsShowTop}
            campaignItems={this.state.campaignItems}
            settingsOptions={this.state.settingsOptions}
            currentNumberOfMail={this.state.currentNumberOfMail}
            user={this.state.user}
            maxCountNumber={this.state.maxCountNumber}
            isLoggedIn={this.state.isLoggedIn}
            valueUniqKey={this.state.valueUniqKey}
            showLayerTop={() => this.showLayerTop()}
            showLayerBottom={() => this.showLayerBottom()}
            removeItemFromCampaign={(email, index) => this.removeItemFromCampaign(email, index)}
            updateStatusSendAction={() => this.updateStatusSendAction()}
            handleShowCustomModal={(msg) => this.handleShowCustomModal(msg, false)}
            handleChangeLoading={(isShow, msg) => this.handleChangeLoading(isShow, msg)}
            createNewCampaign={this.createNewCampaign}
            shouldSecondProgressBar={this.state.shouldSecondProgressBar}
            results={this.state.results}
          />
          <div className="content-header">
            <h1 className="content-header__title text-big text-light  text-dark ">szybko wyszukaj <span className="text-main-color ">wartościowe adresy e-mail   <br /> potencjalnych klientow b2b</span> (leadów)</h1>
            <h2 className="content-header__title text-medium text-dark">twórz <span className="text-main-color ">bazy e-mailingowe </span> i wysyłaj <span className="text-main-color ">mailingi odporne</span></h2>
            <h2 className="content-header__title text-medium text-dark"><span className="text-main-color ">na filtry antyspamowe</span>, śledź <span className="text-main-color ">statystyki odczytu</span></h2>
            <h2 className="content-header__title text-medium text-dark"><span className="text-main-color "><a className="text-main-color" style={{ textDecoration: "none", fontSize: '12px' }} href="https://zamow.contact-hunter.com/">Zobacz pełną listę możliwości systemu</a></span></h2>
          </div>

          <div className="App__container">
            <SearchSection
              getCurrentOption={this.getCurrentOption}
              handleChangeLoading={(isShow, msg) => this.handleChangeLoading(isShow, msg)}
              checkValidKey={(res) => this.checkValidKey(res)}
              handleShowCustomModal={(msg) => this.handleShowCustomModal(msg, false)}
              handleChangeResultsSearch={(obj) => this.handleChangeResultsSearch(obj)}
            />
            {(Object.keys(this.state.results).length !== 0 && this.state.results.constructor === Object) &&
              <ResultsItems
                results={this.state.results}
                contacts={this.state.campaignItems.length}
                toggleItemToCampaign={(id, index, checked) => this.toggleItemToCampaign(id, index, checked)}
                sentSuccessfullyEmails={this.props.emailsSuccesfullySentAmount}
              />
            }
          </div>
          <div className={`layer-wrapper layer-wrapper--bottom ${this.state.layerIsShowBottom ? 'layer-wrapper--bottom--show' : ''}`}>
            <div className="footer-wrapper">
              <div className="footer-wrapper__circle">
                <div className="footer-wrapper__circle--wrapper">
                  <div className="footer-wrapper__circle--shadow"></div>
                  <div className="footer-wrapper__circle--inner">
                    <img src={profilImg} alt="" />
                  </div>
                </div>
              </div>
              {this.state.isLoggedIn ? (
                <UserProfile
                  updateCharts={() => this.getChartsData()}
                  turnOnLayer={this.turnOnLayer}
                  turnOffLayer={this.turnOffLayer}
                  user={this.state.user}
                  charts={this.state.charts}
                  logOut={() => this.logOutUser()}
                  showLayerBottom={() => this.showLayerBottom()}
                />
              ) : (
                  <Login
                    turnOffLayer={this.turnOffLayer}
                    handleLogin={(e) => this.loginUser(e)}
                    valueUniqKey={this.state.valueUniqKey}
                    handleChangeUniqKey={(e) => this.handleChangeUniqKey(e)}
                    setUniqKey={(e) => this.setUniqKey(e)}
                    showLayerBottom={() => this.showLayerBottom()}
                  />
                )}
            </div>
          </div>

          {this.renderCustomModal(this.state.modalCustomText)}

          {this.state.isLoadingModalAction &&
            <Modal
              handleCloseModal={() => this.handleCloseModal()}
              showBtn={false}
              showLink={false}
            >
              <p className="text-center">{this.state.modalCustomTextLoading} </p>
              <Loader />
            </Modal>}

          <div style={this.state.isShadowLayerVisible ? { opacity: '0.9' } : { opacity: "0", zIndex: '-999' }} className="shadow-layer"></div>
        </div>
        {this.state.isLoggedIn && <TipBar />}
        {!this.state.isLoggedIn ?
          <div className="premium-info">
            <a href={"https://zamow.contact-hunter.com/"} rel="noopener noreferrer" target="_blank">Kup konto premium</a>
            <a className="howto" href="https://zamow.contact-hunter.com/jak-aktywowac-konto-premium" rel="noopener noreferrer" target="_blank">Jak aktywować konto premium?</a>
            <div className="info-container">
              <a className="info" href="https://zamow.contact-hunter.com" rel="noopener noreferrer" target="_blank">Cennik</a>
              <a className="info" href="https://zamow.contact-hunter.com/regulamin" rel="noopener noreferrer" target="_blank">Regulamin</a>
              <a className="info" href="https://zamow.contact-hunter.com/pomoc" rel="noopener noreferrer" target="_blank">Pomoc</a>
            </div>
          </div>
          :
          null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    emailsOpenedAmount: state.emailsOpenedAmount,
    emailsWantedToSendAmount: state.emailsWantedToSendAmount,
    emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    progressBarValue: state.progressBarValue,
    maxEmailsAmount: state.maxEmailsAmount,
    stopInterval: state.stopInterval
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload)),
    setEmailsOpenedAmount: payload => dispatch(setEmailsOpenedAmount(payload)),
    setEmailsWantedToSendAmount: payload => dispatch(setEmailsWantedToSendAmount(payload)),
    setEmailsSuccesfullySentAmount: payload => dispatch(setEmailsSuccesfullySentAmount(payload)),
    setProgressBarValue: payload => dispatch(setProgressBarValue(payload)),
    setMaxEmailsAmount: payload => dispatch(setMaxEmailsAmount(payload)),
    setStopInterval: payload => dispatch(setStopInterval(payload)),
    setMovedMessages: payload => dispatch(setMovedMessages(payload)),
    setTabsData: payload => dispatch(setTabsData(payload)),
    setCurrentItem: payload => dispatch(setCurrentItem(payload)),
    setBlockFirstPatterns: payload => dispatch(setBlockFirstPatterns(payload)),
    setBlockSecondPatterns: payload => dispatch(setBlockSecondPatterns(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(App)