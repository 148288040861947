import React, { useState, memo } from 'react'
import uuid from 'uuid'

import searchImage from './../../assets/img/szukaj.png';

const options = [
  {
    value: 'standard',
    label: 'STANDARDOWO'
  },
  {
    value: 'details',
    label: 'SZCZEGÓŁOWO'
  }
]


export const SearchInputs = memo((props) => {
  const [value, setValue] = useState("standard");
  return (
    <form
      onSubmit={(e) => props.handlerSubmitSearch(e)}
      className="search-wrapper">
      {props.currentSearchOption === 'dataPersonal' &&
        <div className="search-wrapper__double-item">
          <div className="search-wrapper__item search-wrapper__item--half">
            <label htmlFor="name_person" className="input-label">Imię</label>
            <input
              value={props.searchName}
              onChange={(e) => props.handlerChangeSearchName(e)}
              className="search-wrapper__input"
              name="name_person"
              id="name_person"
              type="text"
              placeholder="IMIĘ" />
            {props.searchNameError && <div className="input-error">{props.searchNameError}</div>}
          </div>
          <div className="search-wrapper__item search-wrapper__item--half">
            <label htmlFor="surname_person" className="input-label">Nazwisko</label>
            <input
              value={props.searchSurname}
              onChange={(e) => props.handlerChangeSearchSurname(e)}
              className="search-wrapper__input"
              id="surname_person"
              name="surname_person"
              type="text"
              placeholder="NAZWISKO" />
            {props.searchSurnameError && <div className="input-error">{props.searchSurnameError}</div>}
          </div>
        </div>
      }
      <div className="search-wrapper__item">
        <label htmlFor="url_company" className="input-label">Adres strony www firmy (wymagany)</label>
        <input
          value={props.searchCompanyUrl}
          onChange={(e) => props.handlerChangeSearchCompanyUrl(e)}
          className="search-wrapper__input"
          id="url_company"
          name="url_company"
          type="text"
          placeholder="ADRES STRONY WWW FIRMY" />
        {props.currentSearchOption === 'dataPersonal' && props.searchCompanyUrlError__dataPersonal && <div className="input-error">{props.searchCompanyUrlError__dataPersonal}</div>}
        {props.currentSearchOption === 'www' && props.searchCompanyUrlError__www && <div className="input-error">{props.searchCompanyUrlError__www}</div>}
      </div>
      <div className="search-wrapper__item">
        <label htmlFor="name_company" className="input-label">Nazwa firmy (opcjonalnie)</label>
        <input
          value={props.searchCompanyName}
          onChange={(e) => props.handlerChangeSearchCompanyName(e)}
          className="search-wrapper__input"
          id="name_company"
          name="name_company"
          type="text"
          placeholder="NAZWA FIRMY" />
      </div>

      {props.currentSearchOption === 'dataPersonal' &&

        <div className="search-wrapper__container-action text-white" >
          <button
            className="search-wrapper__btn-submit"
            type="submit">
            <img className="search-wrapper__image_submit" src={searchImage} alt="" />
          </button>
        </div>

      }

      {props.currentSearchOption === 'www' &&
        <div className="search-wrapper__container-action search-wrapper__container-action--smaller text-white smaller" >
          <button
            className="search-wrapper__btn-submit"
            type="submit">
            <img className="search-wrapper__image_submit" src={searchImage} alt="" />
          </button>

          <select className="form__select" name="" id=""
            value={value}
            onChange={(e) => {
              props.handleChangeSearchType(e.target.value);
              setValue(e.target.value);
            }}
          >
            {options.map(option => {
              return (
                <option value={`${option.value}`} key={option.value}>{option.label}</option>
              )
            })}
          </select>
        </div>
      }
    </form>
  )
})