import React, { memo } from 'react'

import www from './../../assets/img/svg2.png';
import data from './../../assets/img/svg.png';
import { connect } from 'react-redux'
import { setTip, setHref } from '../../redux/reducer'

const SearchOption = memo((props) => {

  let url = null;

  switch (props.dataSearch.type) {
    case 'www':
      url = www;
      break;
    case 'dataPersonal':
      url = data;
      break;
    default:
      break;
  }

  return (
    <div className={`search-option search-option--${props.numberOfOption}`}>
      <div
        className={`search-option__circle ${props.dataSearch.type !== props.active ? 'search-option__circle--active' : ''}`}>
        <div className="search-option__circle--shadow"></div>
        <div
          data-type={props.dataSearch.type}
          onClick={(e) => {
            props.handleChangeSearchOption(e);
            {
              if (props.numberOfOption === 1) {
                props.setTip('Jak szukać leadów B2B po domenach firm?');
                props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-domenach-firm/");
                props.getCurrentOption('www');
              } else {
                props.setTip('Jak szukać leadów B2B po danych osobowych?');
                props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-danych/");
                props.getCurrentOption('dataPersonal');
              }
            }
          }}
          className={`search-option__circle--inner ${props.dataSearch.type !== props.active ? '' : 'clickedImage'}`}>
          <img
            data-type={props.dataSearch.type}
            src={url} alt=""
          />
        </div>

      </div>
      <p className="search-option__text text-white text-center ">{props.dataSearch.description}</p>
    </div >
  );
})

const mapStateToProps = state => {
  return {
    tip: state.tip,
    href: state.href
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(SearchOption)

