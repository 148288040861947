import React from 'react'

export const Loader = () => {
  return (
    <div className="cssload-spinner">
      <div className="cssload-cube cssload-cube0"></div>
      <div className="cssload-cube cssload-cube1"></div>
      <div className="cssload-cube cssload-cube2"></div>
      <div className="cssload-cube cssload-cube3"></div>
      <div className="cssload-cube cssload-cube4"></div>
      <div className="cssload-cube cssload-cube5"></div>
      <div className="cssload-cube cssload-cube6"></div>
      <div className="cssload-cube cssload-cube7"></div>
      <div className="cssload-cube cssload-cube8"></div>
      <div className="cssload-cube cssload-cube9"></div>
      <div className="cssload-cube cssload-cube10"></div>
      <div className="cssload-cube cssload-cube11"></div>
      <div className="cssload-cube cssload-cube12"></div>
      <div className="cssload-cube cssload-cube13"></div>
      <div className="cssload-cube cssload-cube14"></div>
      <div className="cssload-cube cssload-cube15"></div>
    </div>
  )
}
