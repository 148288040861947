import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer } from './reducer'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['tip', 'href', 'shouldSpamScoringAppear', 'currentItem']
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, composeWithDevTools(
    applyMiddleware()
));
export const persistor = persistStore(store);