import React, { memo } from 'react'
import styled, { keyframes } from 'styled-components'

const fillProgressBar = (progress, prevProgress) => keyframes`
    from{
        transform: rotate(${(180 + progress * 1.8) - (progress - prevProgress) * 1.8}deg);
    }
    to{
        transform: rotate(${180 + progress * 1.8}deg);
    }
}  
`;

const textFollowsProgressBar = (progress, prevProgress) => keyframes`
    from {
        transform: rotate(${(progress * 1.8) - (progress - prevProgress) * 1.8}deg) translateX(-150px) rotate(${(-progress * 1.8) + (progress - prevProgress) * 1.8}deg);
    }
    to{
        transform: rotate(${progress * 1.8}deg) translateX(-150px) rotate(${-progress * 1.8}deg);
    }
`;
const textFollowsProgressBarMobile = (progress, prevProgress) => keyframes`
    from {
        transform:  rotate(${(progress * 1.8) - (progress - prevProgress) * 1.8}deg) translateX(-110px) rotate(${(-progress * 1.8) + (progress - prevProgress) * 1.8}deg);
    }
    to{
        transform: rotate(${progress * 1.8}deg) translateX(-110px) rotate(${-progress * 1.8}deg);
    }
`;


const GlobalWrapper = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: none;

    @media (max-width: 768px) {
        width: 155px;
        height: 155px;
        left: 60%;
    }
`;

const ProgressBarWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 50%;
    border-radius: 100px 100px 0 0;
    overflow: hidden;
`;

const StyledProgressBar = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #11C4D7;
    border-radius: 100px 100px 0 0;
    animation : ${props => fillProgressBar(props.progress, props.prevProgress)} 1s ease-in-out both;
    transform-origin: bottom center;
`;

const StyledProgressBarNumber = styled.div`
    position: absolute;
    top: calc(50% + 5px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight:bold;
    background: transparent;
    border-radius: 50%;
    font-size: 13px;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    ::before{
        content: '${props => props.progress}%';
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        background: #11C4D7;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation : ${props => textFollowsProgressBar(props.progress, props.prevProgress)} 1s ease-in-out both;
    }
    @media (max-width: 768px) {
        ::before{
            width: 35px;
            height: 35px;
            font-size: 9px;
            animation : ${props => textFollowsProgressBarMobile(props.progress, props.prevProgress)} 1s ease-in-out both;
        }
    }
`;

const ProgressBar = memo((props) => {
    return (
        <GlobalWrapper className="progressBar">
            <ProgressBarWrapper>
                <StyledProgressBar progress={props.progress} prevProgress={props.prevProgress}></StyledProgressBar>
            </ProgressBarWrapper>
            <StyledProgressBarNumber progress={props.progress} prevProgress={props.prevProgress}>{props.progress + "%"}</StyledProgressBarNumber>
        </GlobalWrapper>
    )
})

export default ProgressBar