import React, { useEffect, useState, memo } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { connect } from 'react-redux'
import { setShouldSpamScoringAppear } from '../../redux/reducer'
import { CircularProgressbar } from 'react-circular-progressbar';

const animation = keyframes`
    from{ 
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;
const reverseAnimation = keyframes`
    from{ 
        opacity: 1;
    }
    to{
        opacity: 0;
    }
`;

const StyledSpamScoring = styled.div`
    width: 70%;
    height: 70%;
    background: #11C4D7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    border-radius: 15px;
    box-shadow: 0px 0px 10px -1px black;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    color: white;
    padding: 20px;
    opacity: 0;
    ${({ shouldFadeIn }) => shouldFadeIn ? css`
        animation: ${animation} 1s ease-in-out both;
        ` : css`
        animation: ${reverseAnimation} 0.5s ease-in-out both;
    `}
    @media (max-width: 768px) {
        width: 85%;
        height: 80%;
        padding: 15px;
        .CircularProgressbar{
            width: 100px;
            height: 100px;
        }
    }
`;

const StyledMarkAnnotation = styled.div`
    font-size: 14px;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;
const StyledAnnotation = styled.div`
    margin-bottom: 20px;
    @media (max-width: 768px) {
        margin-bottom: 15px;
    }
`;
const StyledAnnotation2 = styled.div`
    font-weight: bold;
`;
const HeaderWrapper = styled.div`
    text-align: center;
`;
const ProgressBarWrapper = styled.div`
    position: relative;
    `;
const TextWrapper = styled.div`
    position: absolute;
    top: calc(50% + 3px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;
const ProgressText = styled.div`
    font-size: 12px;
    @media (max-width: 768px) {
        font-size: 10px;
    }
`;
const ProgressNumber = styled.div`
    font-size: 30px;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
const ProgressMark = styled.div`
    font-weight: 900;
    font-size: 30px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;
const StyledButton = styled.button`
    min-width: 150px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    background: none;
    border: 2px solid white;
    margin: 5px 10px;
    :hover{
        cursor: pointer;
    }
    @media (max-width: 768px) {
        min-width: 100px;
        font-size: 9px;
    }
`;
const SpamScoring = memo((props) => {
    const [percentage, setPercentage] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [shouldFadeIn, setShouldFadeIn] = useState(false);
    useEffect(() => {
        setShouldFadeIn(true);
        props.getSpamScoring();
    }, [])
    useEffect(() => {
    }, [props.spamScoringResult])
    useEffect(() => {
        if (percentage < 100 && !props.spamScoringResult.isFinished) {
            setTimeout(() => {
                setPercentage(percentage + 1);
            }, 4200);
        } else if (percentage < 100 && props.spamScoringResult.isFinished) {
            setTimeout(() => {
                setPercentage(percentage + 1);
            }, 100);
        } else if (percentage === 100 && props.spamScoringResult.isFinished === true) {
            setIsChecked(true);
        }
    }, [percentage, props.spamScoringResult.isFinished])
    return (
        <StyledSpamScoring shouldFadeIn={shouldFadeIn}>
            {!isChecked ?
                <>
                    <HeaderWrapper>
                        <StyledAnnotation>Trwa test twojej wiadomości filtrami antyspamowymi</StyledAnnotation>
                        <StyledAnnotation2>Prosimy o cierpliwość</StyledAnnotation2>
                    </HeaderWrapper>
                    <ProgressBarWrapper>
                        <CircularProgressbar value={percentage} />
                        <TextWrapper>
                            <ProgressText>Wykonano</ProgressText>
                            <ProgressNumber>{percentage + "%"}</ProgressNumber>
                            <ProgressText>Testu</ProgressText>
                        </TextWrapper>
                    </ProgressBarWrapper>
                </>
                :
                <>
                    <HeaderWrapper>
                        <StyledAnnotation>System przetestował treść twojej wiadomości filtrami antyspamowymi</StyledAnnotation>
                        <StyledAnnotation2>Ocena treści</StyledAnnotation2>
                        <ProgressMark>{props.spamScoringResult.mark}</ProgressMark>
                    </HeaderWrapper>
                    <ProgressBarWrapper>
                        <CircularProgressbar value={props.spamScoringResult.percent} />
                        <TextWrapper>
                            <ProgressText>Ocena</ProgressText>
                            <ProgressNumber>{props.spamScoringResult.percent + "%"}</ProgressNumber>
                        </TextWrapper>
                    </ProgressBarWrapper>
                    <StyledMarkAnnotation>{props.spamScoringResult.annotation}</StyledMarkAnnotation>
                    <ButtonsWrapper>
                        <StyledButton onClick={() => {
                            props.edit();
                        }}>Edytuj treść</StyledButton>
                        <StyledButton onClick={() => {
                            props.sendMessage();
                        }}>Wyślij kampenie</StyledButton>
                    </ButtonsWrapper>
                </>
            }
        </StyledSpamScoring>
    )
})

const mapStateToProps = state => {
    return {
        shouldSpamScoringAppear: state.shouldSpamScoringAppear
    }
}

const mapDisptachToProps = dispatch => {
    return {
        setShouldSpamScoringAppear: payload => dispatch(setShouldSpamScoringAppear(payload))
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(SpamScoring)
