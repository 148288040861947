import React, { useEffect, useState, useRef, memo } from 'react'
import uuid from 'uuid'
import $ from 'jquery'
import axios from 'axios'
import { VariableSizeList } from 'react-window';
import { List, CellMeasurerCache, CellMeasurer } from 'react-virtualized'
import MessageSetting from '../MessageUser/MessageSetting'

import CampaignItem from './CampaignItem';
import { connect } from 'react-redux'

let x = 1;
let sizes = [];
let y = 0;
let mobile = false;

const CampaignItems = (props) => {
  const LIST = useRef()
  const [rerender, setRerender] = useState();
  const [mobileBreakPoint, setMobileBreakPoint] = useState(false)
  const [foo, setFoo] = useState(Math.random());
  const list = useRef();
  window.list = list;
  useEffect(() => {
    if (list.current) {
      list.current.scrollToItem(props.currentItem + 1);
    }
  }, [list.current])
  useEffect(() => {
    let emails = [];
    props.results.map((element, index) => {
      emails.push(element.email);
      let height = 110;
      let empty = 0;
      if (element.surname === "" && element.name === "") empty++;
      if (element.position === "") empty++;
      if (element.companyName === "") empty++;
      if (empty === 0) height = 110;
      if (empty === 1) height = 84;
      if (empty === 2) height = 62;
      if (empty === 3) height = 60;
      if (props.results[index]) props.results[index].height = height
    })
    axios.post('https://contact-hunter.com/pl/get_data_edit.php', {
      emailAddress: emails
    }).then(res => {
      if (res.data) {
        res.data.map((element, index) => {
          if (props.results[index]) props.results[index].name = res.data[index][0]
          if (props.results[index]) props.results[index].surname = res.data[index][1]
          if (props.results[index]) props.results[index].position = res.data[index][2]
          if (props.results[index]) props.results[index].companyName = res.data[index][3]
          if (props.results[index]) props.results[index].height = res.data[index][4]
          if (index === res.data.length - 1) {
            setRerender(Math.random());
            x = 2;
          }
        })
      }
    })
  }, [props.results])
  useEffect(() => {
    if (x === 2) {
      props.results.map(item => {
        sizes.push(item.height)
      })
    }
  }, [x])
  useEffect(() => {
    window.onresize = () => {
      props.rerender();
      if (window.innerWidth > 769) {
        mobile = false;
        setRerender(Math.random())
      }
      if (window.innerWidth < 770) {
        mobile = true;
        setRerender(Math.random())
      }
    }
  }, [])
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 110
  })

  const rowRenderer = ({ style, key, index, parent }) => {
    const setName = name => {
      if (props.results[index]) props.results[index].name = name;
      localStorage.setItem('campaignItems', JSON.stringify(props.results))
    }
    const setSurname = surname => {
      if (props.results[index]) props.results[index].surname = surname;
      localStorage.setItem('campaignItems', JSON.stringify(props.results))
    }
    const setPosition = position => {
      if (props.results[index]) props.results[index].position = position;
      localStorage.setItem('campaignItems', JSON.stringify(props.results))
    }
    const setCompanyName = companyName => {
      if (props.results[index]) props.results[index].companyName = companyName;
      localStorage.setItem('campaignItems', JSON.stringify(props.results))
    }
    const reset = () => {
      // cache.clearAll();
      // LIST.current.recomputeRowHeights(0);
      // LIST.current.forceUpdateGrid();
      // setFoo(Math.random())
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          {index === 0 && <MessageSetting
            setRerender={setRerender}
            results={props.results}
            scrollList={true}
            marginLeftRight={true}
            noX={true}
            setShowPatterns={show => props.setShowPatterns(show)}
            setShowSecondPatterns={show => props.setShowSecondPatterns(show)}
            sendSecondTestMessage={props.sendSecondTestMessage}
            clearResults={props.clearResults}
            titleMessage={props.titleMessage}
            titleMessageSecond={props.titleMessageSecond}
            setFirstTopic={props.setFirstTopic}
            setSecondTopic={props.setSecondTopic}
            shouldImportExportAppear={props.shouldImportExportAppear}
            shouldSpamScoringAppearProOption={props.shouldSpamScoringAppearProOption}
            shouldAccountBePro={props.shouldAccountBePro}
            setEmailUsername={props.setEmailUsername}
            setEmailPassword={props.setEmailPassword}
            emailUsername={props.emailUsername}
            emailPassword={props.emailPassword}
            outcomingMail={props.outcomingMail}
            incomingMail={props.incomingMail}
            encryptionWay={props.encryptionWay}
            setoutcomingMail={props.setoutcomingMail}
            setEncryptionWay={props.setEncryptionWay}
            setincomingMail={props.setincomingMail}
            currentOptionForCloseButton={props.currentOptionForCloseButton}
            turnOffLayer={props.turnOffLayer}
            sendTestMessage={props.sendTestMessage}
            updateSecondPattern={props.updateSecondPattern}
            gmailUsername={props.gmailUsername}
            gmailPassword={props.gmailPassword}
            setGmailUsername={props.setGmailUsername}
            setGmailPassword={props.setGmailPassword}
            contentMessage={props.contentMessage}
            option={props.option}
            showLayerTop={() => props.showLayerTop()}
            handleChangeMessageOption={(e) => props.handleChangeMessageOption(e)}
            handleChangeTitleMessage={(e) => props.handleChangeTitleMessage(e)}
            handleChangeContentMessage={(e) => props.handleChangeContentMessage(e)}
            handleChangeContentSecondMessage={(e) => props.handleChangeContentSecondMessage(e)}
            handleChangeSignatureMessage={(e) => props.handleChangeSignatureMessage(e)}
            handleChangeNumberMessage={(e) => props.handleChangeNumberMessage(e)}
            handleChangeHour={(value) => props.handleChangeHour(value)}
            handleChangeHourSecondMsg={(value) => props.handleChangeHourSecondMsg(value)}
            handleChangeLawNote={(value) => props.handleChangeLawNote(value)}
            handleChangeUserMail={(value) => props.handleChangeUserMail(value)}
            handleChangeUserName={(value) => props.handleChangeUserName(value)}
            handleChangeUserSurname={(value) => props.handleChangeUserSurname(value)}
            currentMessageOption={props.currentMessageOption}
            currentTitle={props.currentTitle}
            currentMessage={props.currentMessage}
            contentMessageSecond={props.contentMessageSecond}
            signatureMessage={props.signatureMessage}
            currentTypMessage={props.currentTypMessage}
            valueHour={props.valueHour}
            valueSecondHour={props.valueSecondHour}
            valueLawNote={props.valueLawNote}
            userMail={props.userMail}
            userName={props.userName}
            userSurname={props.userSurname}
            showPatterns={props.showPatterns}
            setShowPatterns={props.setShowPatterns}
            setPattern={props.setPattern}
            pattern={props.pattern}
            updatePattern={props.updatePattern}
            deliveryTime={props.deliveryTime}
            timeFor2Messages={props.timeFor2Messages}
            changeDeliveryTime={props.changeDeliveryTime}
            changeTimeFor2Messages={props.changeTimeFor2Messages}
          />}
          <CampaignItem
            reset={reset}
            setRerender={setRerender}
            rerender={rerender}
            setName={setName}
            setSurname={setSurname}
            setPosition={setPosition}
            setCompanyName={setCompanyName}
            key={props.results[index].email}
            data={props.results[index]}
            index={index}
            layer={props.layer}
            sentSuccessfullyEmails={props.sentSuccessfullyEmails}
            removeItemFromCampaign={props.removeItemFromCampaign}
          />
        </div>
      </CellMeasurer>
    )
  }


  return (
    <div className="results-search results-search--campaign" style={props.results.length !== 0 ? null : { display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '50px', fontSize: '14px' }}>
      <div className="results-search__wrapper-campaign">
        <div className="results-search__list" style={props.results.length !== 0 ? null : { display: "flex", justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center', maxWidth: '400px' }}>
          {props.results.length !== 0 ?
            <List
              renderProps={foo}
              width={$('.results-search__wrapper-campaign').width()}
              height={window.innerHeight - 300}
              rowCount={props.results.length}
              rowRenderer={rowRenderer}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              ref={LIST}

            />
            :
            <div className="emails-annotation" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: 'Work Sans, sans-serif', color: '#12c4d8' }}>
              <div>Nie wybrano żadnych adresów e-mail.</div>
              <div> Aby rozpocząć kampanię wyszukaj lub zaimportuj kontakty.</div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentItem: state.currentItem
  }
}

export default connect(mapStateToProps, null)(CampaignItems)
