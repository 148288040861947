import React, { useEffect, memo } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import $ from 'jquery'

export const Chart = memo((props) => {
  useEffect(() => {
    $('.chart-wrapper').css('animation', 'fadeIn 1.3s ease-in-out both');
    return () => {
      $('.chart-wrapper').css('animation', 'none');
    }
  }, []);
  return (
    <div className="chart-wrapper">
      <p className="chart-title text-center text-bold">limit
        <span className="text-main-color text-center"> {props.data.title}</span>
      </p>
      <CircularProgressbarWithChildren
        value={props.data.usePercent}
        strokeWidth={2}
        styles={{
          path: {
            stroke: '#12c4d8',
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
          trail: {
            stroke: 'rgba(18, 196, 216, .2)',
          },
        }}
      >
        <div className="chart-circle text-center">
          <p className="chart-circle__percent-use text-light text-big-xl text-main-color">{props.data.usePercent}
            <span className="text-big text-to-top">%</span>
          </p>
          <p className="chart-circle__details text-small  text-bold">
            pozostało <br /> {props.data.limitLeft}
            <span className="text-light"> / {props.data.limitTarget} <br />{props.data.postfix}</span>
          </p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
})