import React, { Component } from 'react'

import SearchOption from './SearchOption';
import { SearchInputs } from './SearchInputs';

import validator from 'validator'

const URL = {
  dataPersonal: 'https://contact-hunter.com/pl/emails_from_data.php',
  www: 'https://contact-hunter.com/pl/hunter_curl.php',
}

class SearchSection extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      searchName: '',
      searchCompanyUrl: '',
      searchCompanyName: '',
      searchSurname: '',

      searchNameError: "",
      searchCompanyUrlError__dataPersonal: "",
      searchSurnameError: "",

      searchCompanyUrlError__www: "",
      searchOptions: [
        {
          type: 'dataPersonal',
          description: 'po danych'
        },
        {
          type: 'www',
          description: 'po adresie www'
        }
      ],
      currentSearchOption: 'dataPersonal',
      standardSearch: 'standard',
    }
  }

  handleChangeSearchType(e) {
    this.setState({
      standardSearch: e
    })
  }

  makeQuery(obj) {

    let esc = encodeURIComponent;

    return Object.keys(obj)
      .map(k => `${esc(k)}=${esc(obj[k])}`)
      .join('&');
  }

  clearUrl = () => {

    const { searchCompanyUrl } = this.state;
    const regex = /^https?:\/\/|\/|www.|/gi;
    const properCompanyUrl = searchCompanyUrl.replace(regex, "");
    return properCompanyUrl;

  }

  validate = () => {

    const { searchName, searchSurname, searchCompanyUrl } = this.state;

    if (this.state.currentSearchOption === 'dataPersonal') {

      this.setState({
        searchCompanyUrlError__www: "",
      })

      validator.isEmpty(searchName) ? this.setState({
        searchNameError: 'Uzupełnij wymagane pola'
      }) : this.setState({ searchNameError: "" });

      validator.isEmpty(searchSurname) ? this.setState({
        searchSurnameError: 'Uzupełnij wymagane pola'
      }) : this.setState({ searchSurnameError: "" });

      validator.isEmpty(searchCompanyUrl) ? this.setState({
        searchCompanyUrlError__dataPersonal: 'Uzupełnij wymagane pola'
      }) : this.setState({ searchCompanyUrlError__dataPersonal: "" });

      if (!validator.isEmpty(searchName) && !validator.isEmpty(searchSurname) && !validator.isEmpty(searchCompanyUrl)) {
        return true;
      } else {
        return false;
      }

    } else if (this.state.currentSearchOption === 'www') {

      this.setState({
        searchNameError: "",
        searchSurnameError: "",
        searchCompanyUrlError__dataPersonal: "",
      })

      validator.isEmpty(searchCompanyUrl) ? this.setState({
        searchCompanyUrlError__www: 'Uzupełnij wymagane pola'
      }) : this.setState({ searchCompanyUrlError__www: "" });

      if (!validator.isEmpty(searchCompanyUrl)) {
        return true;
      } else {
        return false;
      }

    }

  }

  handlerSubmitSearch(e) {

    e.preventDefault();

    this.setState({
      searchCompanyUrl: this.clearUrl()
    })

    const properUrl = this.clearUrl();

    if (this.validate()) {

      this.props.handleChangeLoading(true, 'Trwa przeszukiwanie, prosimy o chwilę cierpliwości, może to potrwać nawet do kilku minut.');

      const data = {
        searchName: this.state.searchName,
        searchSurname: this.state.searchSurname,
        searchCompanyUrl: properUrl,
        searchCompanyName: this.state.searchCompanyName,
        standardSearch: this.state.standardSearch
      }

      fetch(`${URL[this.state.currentSearchOption]}?${this.makeQuery(data)}`, {
        method: "GET",
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(response => {

          this.props.handleChangeLoading(false, '');

          const [currentStatusKey, errorMsg, results] = [...response]

          if (!this.props.checkValidKey(currentStatusKey)) return false;

          if (errorMsg.length > 0) {

            if (errorMsg[0].includes('sugestia')) {
              this.props.handleShowCustomModal(errorMsg[0]);
              this.props.handleChangeResultsSearch(results)
            }
            else if (errorMsg.length > 1) {
              this.props.handleShowCustomModal(

                errorMsg.reduce((val, el) => val + "<br/>" + el)

              )
            } else {
              this.props.handleShowCustomModal(errorMsg[0])
              this.props.handleChangeResultsSearch({})
            }

          } else {
            this.props.handleChangeResultsSearch(results)
          }
        })
        .catch(err => {
          this.props.handleChangeLoading(false, '');
          console.log(err)
        })

    }

  }

  handlerChangeSearchName(e) {
    this.setState({
      searchName: e.target.value
    })
  }

  handlerChangeSearchSurname(e) {
    this.setState({
      searchSurname: e.target.value
    })
  }

  handlerChangeSearchCompanyUrl(e) {
    this.setState({
      searchCompanyUrl: e.target.value
    })
  }

  handlerChangeSearchCompanyName(e) {
    this.setState({
      searchCompanyName: e.target.value
    })
  }

  e = {
    target: {
      dataset: {
        type: 'www'
      }
    }
  }

  componentDidMount() {
    this.handleChangeSearchOption(this.e);
  }

  handleChangeSearchOption(e) {
    this.props.getCurrentOption(e.target.dataset.type);
    this.setState({
      currentSearchOption: e.target.dataset.type,
      searchName: '',
      searchCompanyUrl: '',
      searchCompanyName: '',
    })
  }

  render() {
    return (
      <section className="search-section">
        {this.state.searchOptions.map((element, index) => {
          return (
            <SearchOption
              getCurrentOption={this.props.getCurrentOption}
              handleChangeSearchOption={(e) => this.handleChangeSearchOption(e)}
              dataSearch={element}
              numberOfOption={index}
              key={index}
              active={this.state.currentSearchOption}
            />
          )
        })}

        <div className="search-section__title-wrapper text-right text-white ">
          <h3 className="search-section__title text-normal"
            dangerouslySetInnerHTML={{ __html: this.state.currentSearchOption === 'dataPersonal' ? 'wytrop zweryfikowane adresy e-mail<br> szukanych osób' : 'znajdź skrzynki e-mail<br> z domeną firmy' }} >
          </h3>
        </div>
        <SearchInputs
          currentSearchOption={this.state.currentSearchOption}
          standardSearch={this.state.standardSearch}
          handleChangeSearchType={(e) => this.handleChangeSearchType(e)}
          handlerSubmitSearch={(e) => this.handlerSubmitSearch(e)}
          searchName={this.state.searchName}
          searchCompanyUrl={this.state.searchCompanyUrl}
          searchCompanyName={this.state.searchCompanyName}
          searchCompanySurname={this.state.searchCompanySurname}
          handlerChangeSearchSurname={(e) => this.handlerChangeSearchSurname(e)}
          handlerChangeSearchName={(e) => this.handlerChangeSearchName(e)}
          handlerChangeSearchCompanyUrl={(e) => this.handlerChangeSearchCompanyUrl(e)}
          handlerChangeSearchCompanyName={(e) => this.handlerChangeSearchCompanyName(e)}
          searchNameError={this.state.searchNameError}
          searchSurnameError={this.state.searchSurnameError}
          searchCompanyUrlError__dataPersonal={this.state.searchCompanyUrlError__dataPersonal}
          searchCompanyUrlError__www={this.state.searchCompanyUrlError__www}
        />
      </section>
    )
  }
}

export default SearchSection;
