const initialState = {
    tip: 'JAK SZUKAĆ LEADÓW B2B PO DOMENACH FIRM?',
    href: 'https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-domenach-firm/',
    emailsOpenedAmount: 0,
    emailsWantedToSendAmount: 0,
    emailsSuccesfullySentAmount: 0,
    progressBarValue: 0,
    maxEmailsAmount: 0,
    stopInterval: false,
    shouldSpamScoringAppear: false,
    movedMessages: 0,
    tabsData: {
        firstMessage: '',
        firstMessageTitle: '',
        secondMessage: '',
        secondMessageTitle: '',
        userMail: '',
        userName: '',
        userSurname: '',
        userSignature: '',
        lawnote: {},
        gmailUsername: '',
        gmailPassword: '',
        emailUsername: '',
        emailPassword: '',
        incomingMail: '',
        outcomingMail: '',
        encryptionWay: {}
    },
    currentItem: 0,
    blockFirstPatterns: false,
    blockSecondPatterns: false
}

export const reducer = (state = initialState, action) => {
    if (action.type === 'setTabsData') {
        return {
            ...state,
            tabsData: Object.assign({}, state.tabsData, action.payload)
        };
    }
    if (action.type === 'setBlockFirstPatterns') {
        return {
            ...state,
            blockFirstPatterns: action.payload
        };
    }
    if (action.type === 'setBlockSecondPatterns') {
        return {
            ...state,
            blockSecondPatterns: action.payload
        };
    }
    if (action.type === 'setCurrentItem') {
        return {
            ...state,
            currentItem: action.payload
        };
    }
    if (action.type === 'setTip') {
        return {
            ...state,
            tip: action.payload
        };
    }
    if (action.type === 'setHref') {
        return {
            ...state,
            href: action.payload
        };
    }
    if (action.type === 'setEmailsOpenedAmount') {
        return {
            ...state,
            emailsOpenedAmount: action.payload
        };
    }
    if (action.type === 'setEmailsWantedToSendAmount') {
        return {
            ...state,
            emailsWantedToSendAmount: action.payload
        };
    }
    if (action.type === 'setEmailsSuccesfullySentAmount') {
        return {
            ...state,
            emailsSuccesfullySentAmount: action.payload
        };
    }
    if (action.type === 'setProgressBarValue') {
        return {
            ...state,
            progressBarValue: action.payload
        };
    }
    if (action.type === 'setMaxEmailsAmount') {
        return {
            ...state,
            maxEmailsAmount: action.payload
        };
    }
    if (action.type === 'setStopInterval') {
        return {
            ...state,
            stopInterval: action.payload
        };
    }
    if (action.type === 'setShouldSpamScoringAppear') {
        return {
            ...state,
            shouldSpamScoringAppear: action.payload
        };
    }
    if (action.type === 'setMovedMessages') {
        return {
            ...state,
            movedMessages: action.payload
        };
    }
    return state;
}

export const setBlockFirstPatterns = (payload) => {
    return {
        type: 'setBlockFirstPatterns',
        payload
    }
}
export const setBlockSecondPatterns = (payload) => {
    return {
        type: 'setBlockSecondPatterns',
        payload
    }
}
export const setCurrentItem = (payload) => {
    return {
        type: 'setCurrentItem',
        payload
    }
}
export const setTabsData = (payload) => {
    return {
        type: 'setTabsData',
        payload
    }
}
export const setTip = (payload) => {
    return {
        type: 'setTip',
        payload
    }
}
export const setHref = (payload) => {
    return {
        type: 'setHref',
        payload
    }
}
export const setEmailsOpenedAmount = (payload) => {
    return {
        type: 'setEmailsOpenedAmount',
        payload
    }
}
export const setEmailsWantedToSendAmount = (payload) => {
    return {
        type: 'setEmailsWantedToSendAmount',
        payload
    }
}
export const setEmailsSuccesfullySentAmount = (payload) => {
    return {
        type: 'setEmailsSuccesfullySentAmount',
        payload
    }
}
export const setProgressBarValue = (payload) => {
    return {
        type: 'setProgressBarValue',
        payload
    }
}
export const setMaxEmailsAmount = (payload) => {
    return {
        type: 'setMaxEmailsAmount',
        payload
    }
}
export const setStopInterval = (payload) => {
    return {
        type: 'setStopInterval',
        payload
    }
}
export const setShouldSpamScoringAppear = (payload) => {
    return {
        type: 'setShouldSpamScoringAppear',
        payload
    }
}
export const setMovedMessages = (payload) => {
    return {
        type: 'setMovedMessages',
        payload
    }
}
