import React from 'react'

import { saleURL } from './const'

import closeBtn from './../../assets/img/exit.png';

export default class Modal extends React.PureComponent {

  render() {

    let link = this.props.link ? `${saleURL}` : null;

    return (
      <div className="modal-wrapper">
        <div className="modal-outer text-medium" style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {this.props.showLink &&
            <div className="App__close-btn App__close-btn--bg" onClick={this.props.handleCloseModal}>
              <img src={closeBtn} alt="" />
            </div>
          }
          {this.props.children}

          {this.props.showBtn &&
            <a
              className="modal-outer__btn text-medium"
              onClick={this.props.handleCloseModal}
              type="button"
              href={link}
            >OK</a>
          }
        </div>
      </div>
    )
  }
}
