import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import uuid from 'uuid'
import ReactHtmlParser from 'react-html-parser'
import { setTabsData, setBlockFirstPatterns, setBlockSecondPatterns } from '../../redux/reducer'

import CloseButtonImage from '../../assets/img/exit-white.png'

const ModalWrapper = styled.div`
    width: 80%;
    height: 70%;
    background: rgba(17,196,215, 1);
    filter:drop-shadow(0px 0px 3px black);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 15px;
    overflow-y: scroll;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 13px;
        height: 65%;    
    }
`;

const StyledH1 = styled.div`
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 20px auto;
    margin-top: 35px;
    max-width: 50%;
    @media (max-width: 768px) {
        font-size: 14px;
    }
    @media (max-width: 400px) {
        font-size: 11px;
    }
    `;

const StyledH3 = styled.div`
    max-width: 50%;
    margin: 20px auto;
    color: white;
    font-weight: bold;
    font-size: 15px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
    @media (max-width: 400px) {
        font-size: 9px;
    }
`;

const StyledPattern = styled.div`
    width: 80%;
    border: 0.5px solid white;
    min-height: 200px;
    margin: 20px 0px;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    color: white;

    p{
        text-transform: initial;
    }
    h1{
        text-transform: initial;
    }
`;
const CloseButton = styled.div`
    width: 44px;
    height: 44px;
    background: url(${CloseButtonImage}) center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    position: sticky;
    top: 15px;
    left: 15px;
`;

const PatternsModal = memo((props) => {
    const [patterns, setPatterns] = useState();
    const [secondPatterns, setSecondPatterns] = useState();
    useEffect(() => {
        if (props.patterns) {
            setPatterns(props.patterns.map(pattern => {
                let id = pattern.title;
                return (
                    <>
                        <input className="patternRadio" type="radio" name="pattern" id={id} />
                        <label htmlFor={id}>
                            <StyledPattern className="pattern" key={id} onClick={() => {
                                props.setTabsData({
                                    firstMessage: `${pattern.header}\n${pattern.content}`,
                                    firstMessageTitle: pattern.title
                                })
                                props.setShowPatterns(false);
                                props.setBlockFirstPatterns(true)
                            }}>
                                <h1>{pattern.header}</h1>
                                <p style={{ marginTop: '15px' }}>{ReactHtmlParser(pattern.content.replace(new RegExp('\r?\n', 'g'), '<br />'))}</p>
                            </StyledPattern>
                        </label>
                    </>
                )
            }));
        }
        if (props.secondPatterns) {
            setSecondPatterns(props.secondPatterns.map(pattern => {
                let id = pattern.title;
                return (
                    <>
                        <input className="patternRadio" type="radio" name="pattern" id={id} />
                        <label htmlFor={id}>
                            <StyledPattern className="pattern" key={id} onClick={() => {
                                props.setTabsData({
                                    secondMessage: `${pattern.header}\n${pattern.content}`,
                                    secondMessageTitle: pattern.title
                                })
                                props.setShowSecondPatterns(false);
                                props.setBlockSecondPatterns(true)
                            }}>
                                <h1>{pattern.header}</h1>
                                <p style={{ marginTop: '15px' }}>{ReactHtmlParser(pattern.content.replace(new RegExp('\r?\n', 'g'), '<br />'))}</p>
                            </StyledPattern>
                        </label>
                    </>
                )
            }));
        }
    }, [])
    return (
        <>
            {!props.blockFirstPatterns && props.showPatterns &&
                <ModalWrapper>
                    <CloseButton onClick={() => props.setShowPatterns(false)} />
                    <StyledH1>Uwaga!</StyledH1>
                    <StyledH3>Obowiązujące przepisy kodeksu cywilnego zabraniają wysyłanie ofert handlowych do osób, które nie wyraziły na to zgody</StyledH3>
                    <StyledH3>Zespół contact-hunter.com sugeruje skorzystanie z gotowych szablonów, które pomogą państwu nawiązać relacje z nowymi klientami w sposób zgodny z obowiązującymi przepisami oraz w formie, która nie zostanie odebrana przez filtry antyspamowe jako oferta handlowa.</StyledH3>
                    {patterns}
                </ModalWrapper>
            }
            {!props.blockSecondPatterns && props.showSecondPatterns &&
                <ModalWrapper onClick={() => props.setShowSecondPatterns(false)}>
                    <CloseButton />
                    <StyledH1>Uwaga!</StyledH1>
                    <StyledH3>Obowiązujące przepisy kodeksu cywilnego zabraniają wysyłanie ofert handlowych do osób, które nie wyraziły na to zgody</StyledH3>
                    <StyledH3>Zespół contact-hunter.com sugeruje skorzystanie z gotowych szablonów, które pomogą państwu nawiązać relacje z nowymi klientami w sposób zgodny z obowiązującymi przepisami oraz w formie, która nie zostanie odebrana przez filtry antyspamowe jako oferta handlowa.</StyledH3>
                    {secondPatterns}
                </ModalWrapper>
            }
        </>
    )
})

const mapStateToProps = state => {
    return {
        blockFirstPatterns: state.blockFirstPatterns,
        blockSecondPatterns: state.blockSecondPatterns
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTabsData: payload => dispatch(setTabsData(payload)),
        setBlockFirstPatterns: payload => dispatch(setBlockFirstPatterns(payload)),
        setBlockSecondPatterns: payload => dispatch(setBlockSecondPatterns(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternsModal)
