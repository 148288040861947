import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import anime from 'animejs'
import { connect } from 'react-redux'

const StyledTipBar = styled.div`
    width: 100%;
    height: 50px;
    background: #11C4D7;
    position: fixed;
    left: 0;
    bottom: 0;
    transform: translateY(-50px);
    flex-wrap: wrap-reverse;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 600px) {
        padding: 10px 5px;
    }
`;

const StyledTipBarSign = styled.div`
    width: 25px;
    height: 25px;
    color: #11C4D7;
    border-radius: 50%;
    background: white;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
        width: 20px;
        height: 20px;
        margin-right: 3px;
    }
    @media (max-width: 450px) {
        width: 13px;
        height: 13px;
        font-size: 8px;
    }
`;

const StyledTipBarAnnotation = styled.div`
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    margin-right: 6px;
    @media (max-width: 600px) {
        font-size: 9px;
    }
    @media (max-width: 450px) {
        font-size: 7px;
    }
    `;

const StyledTipBarText = styled.a`
    color: white;
    white-space: nowrap;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 9px;
        flex: 0.6;
    }
    @media (max-width: 450px) {
        font-size: 7px;
    }
`;

const TipBar = memo(props => {
    const [receivedTip, setReceivedTip] = useState(props.tip);
    useEffect(() => {
        anime({
            targets: '.tipbar',
            translateY: [50, 0],
            duration: '500',
            easing: 'easeInOutSine'
        })
    }, [])
    useEffect(() => {
        if (receivedTip !== props.tip) {
            anime.timeline()
                .add({
                    targets: '.tipbar',
                    translateY: [0, 50],
                    duration: '500',
                    easing: 'easeInOutSine'
                })
                .add({
                    targets: '.tipbar',
                    translateY: [50, 0],
                    duration: '500',
                    easing: 'easeInOutSine'
                });
        }
        setTimeout(() => {
            setReceivedTip(props.tip);
        }, 600);
    }, [props.tip])
    return (
        <StyledTipBar className="tipbar">
            <StyledTipBarSign className="signannotation">?</StyledTipBarSign>
            <StyledTipBarAnnotation className="signannotation">Poradnik:</StyledTipBarAnnotation>
            <StyledTipBarText className="StyledTipBarText" href={props.href} target="_blank" rel="noopener noreferrer">{receivedTip}</StyledTipBarText>
        </StyledTipBar>
    )
}
)

const mapStateToProps = state => {
    return {
        tip: state.tip,
        href: state.href
    }
}

export default connect(mapStateToProps, null)(TipBar)
