import React, { useState, useEffect, memo } from 'react'
import Tabs from './Tabs';
import SettingOption from './SettingOption';
import $ from 'jquery'
import styled from 'styled-components'
import closeBtn from './../../assets/img/exit.png';

import { connect } from 'react-redux'
import { setTip, setHref, setTabsData } from '../../redux/reducer'
import validator from 'validator'

const StyledSendTestMessageButton = styled.button`
  background: transparent;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  right: 20px;
  text-align: center;

  :hover{
    cursor: pointer; 
    border: 1.5px solid white;
  }

  @media (max-width: 600px) {
    font-size: 8px;
    padding: 5px 5px;
    max-width: 150px;
  }
  @media (max-width: 400px) {
    font-size: 6px;
    padding: 5px 5px;
    max-width: 80px;
  }
`;


const MessageSetting = memo(props => {
  const [currentTypMessage, setCurrentTypMessage] = useState(1);
  const [currentMessageOption, setCurrentMessageOption] = useState('message');
  return (
    <section className="search-section search-section--campaign" style={props.results.length > 0 && props.scrollList ? { marginLeft: '10px', marginRight: '10px', boxShadow: 'none' } : props.results.length > 0 && !props.scrollList ? { position: 'absolute', top: '-1000px' } : null}>
      {!props.noX && <div className="App__close-btn App__close-btn--bg" onClick={() => {
        if (props.currentOptionForCloseButton === 'dataPersonal') {
          props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-danych/");
          props.setTip('Jak szukać leadów B2B po danych osobowych?');
        }
        if (props.currentOptionForCloseButton === 'www') {
          props.setTip('Jak szukać leadów B2B po domenach firm?');
          props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-domenach-firm/");
        }
        props.showLayerTop();
        $('.progressBar').fadeOut();
        props.turnOffLayer();
      }}>
        <img src={closeBtn} alt="" />
      </div>}
      {currentTypMessage === 1 && <StyledSendTestMessageButton disabled={!validator.isEmail(props.tabsData.userMail)} onClick={() => {
        props.sendTestMessage();
        props.clearResults();
      }}>Wyślij wiadomość testową na mój adres e-mail</StyledSendTestMessageButton>}
      {currentTypMessage === 2 && <StyledSendTestMessageButton disabled={!validator.isEmail(props.tabsData.userMail)} onClick={() => {
        props.sendSecondTestMessage();
        props.clearResults();
      }}>Wyślij wiadomość testową na mój adres e-mail</StyledSendTestMessageButton>}
      {props.option.map((element, index) => {
        return (
          <SettingOption
            handleChangeMessageOption={setCurrentMessageOption}
            dataSearch={element}
            numberOfOption={index}
            key={index}
            active={currentMessageOption}
            handleChangeNumberMessage={setCurrentTypMessage}
            rerender={props.setRerender ? props.setRerender : null}
          />
        )
      })}
      <Tabs

        setShowPatterns={show => props.setShowPatterns(show)}
        setShowSecondPatterns={show => props.setShowSecondPatterns(show)}

        titleMessage={props.titleMessage}
        titleMessageSecond={props.titleMessageSecond}
        setFirstTopic={props.setFirstTopic}
        setSecondTopic={props.setSecondTopic}
        shouldImportExportAppear={props.shouldImportExportAppear}
        shouldSpamScoringAppearProOption={props.shouldSpamScoringAppearProOption}
        shouldAccountBePro={props.shouldAccountBePro}
        setEmailPassword={props.setEmailPassword}
        setEmailUsername={props.setEmailUsername}
        emailUsername={props.emailUsername}
        emailPassword={props.emailPassword}
        outcomingMail={props.outcomingMail}
        incomingMail={props.incomingMail}
        encryptionWay={props.encryptionWay}
        setoutcomingMail={props.setoutcomingMail}
        setEncryptionWay={props.setEncryptionWay}
        setincomingMail={props.setincomingMail}
        updateSecondPattern={props.updateSecondPattern}
        gmailUsername={props.gmailUsername}
        gmailPassword={props.gmailPassword}
        setGmailUsername={props.setGmailUsername}
        setGmailPassword={props.setGmailPassword}
        contentMessage={props.contentMessage}
        contentMessageSecond={props.contentMessageSecond}
        currentMessageOption={currentMessageOption}
        handleChangeTitleMessage={(e) => props.handleChangeTitleMessage(e)}
        handleChangeContentMessage={(e) => props.handleChangeContentMessage(e)}
        handleChangeContentSecondMessage={props.handleChangeContentSecondMessage}
        handleChangeSignatureMessage={(e) => props.handleChangeSignatureMessage(e)}
        handleChangeNumberMessage={setCurrentTypMessage}
        handleChangeUserName={(e) => props.handleChangeUserName(e)}
        handleChangeUserSurname={(e) => props.handleChangeUserSurname(e)}
        currentTitle={props.currentTitle}
        currentMessage={props.currentMessage}
        signatureMessage={props.signatureMessage}
        currentTypMessage={currentTypMessage}
        handleChangeHour={(value) => props.handleChangeHour(value)}
        handleChangeHourSecondMsg={(value) => props.handleChangeHourSecondMsg(value)}
        handleChangeLawNote={(value) => props.handleChangeLawNote(value)}
        handleChangeUserMail={(value) => props.handleChangeUserMail(value)}
        valueHour={props.valueHour}
        valueSecondHour={props.valueSecondHour}
        valueLawNote={props.valueLawNote}
        userMail={props.userMail}
        userName={props.userName}
        userSurname={props.userSurname}
        setShowPatterns={props.setShowPatterns}
        pattern={props.pattern}
        showPatterns={props.showPatterns}
        setPattern={props.setPattern}
        updatePattern={props.updatePattern}
        changeDeliveryTime={props.changeDeliveryTime}
        changeTimeFor2Messages={props.changeTimeFor2Messages}
      />
    </section>
  )

})

const mapStateToProps = state => {
  return {
    tabsData: state.tabsData
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload)),
    setTabsData: payload => dispatch(setTabsData(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(MessageSetting)
