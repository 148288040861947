import React from 'react'

import MessageSetting from '../MessageUser/MessageSetting';
import CampaignItems from '../Campaign/CampaignItems';
import styled from 'styled-components'
import CloseButtonImage from '../../assets/img/exit.png'
import ClearButtonTopLayer from './ClearButtonTopLayer'

import PatternsModal from '../PatternsModal/PatternsModal'
import Header from './Header';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeId } from '../Utilities/helpersFn'
import { Cookies } from 'react-cookie';
import $ from 'jquery'
import validator from 'validator'
import axios from 'axios';
import ImportExportModal from '../Utilities/ImportExportModal'
import { connect } from 'react-redux'
import { setTip, setHref, setProgressBarValue, setEmailsOpenedAmount, setEmailsWantedToSendAmount, setEmailsSuccesfullySentAmount, setStopInterval, setShouldSpamScoringAppear, setMovedMessages, setTabsData } from '../../redux/reducer'
import SpamScoring from '../Utilities/SpamScoring'

const CloseButton = styled.div`
    width: 44px;
    height: 44px;
    background: url(${CloseButtonImage}) center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
`;

const Modal = styled.div`
  background: #12c4d8;
  width: 50%;
  height: 40%;
  box-shadow: 0px 0px 10px -1px black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  @media (max-width: 1200px) {
     font-size: 13px;
  }
  @media (max-width: 500px) {
     font-size: 11px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 45px;
  @media (max-width: 500px) {
     flex-direction: column;
     margin-top: 30px;
  }
`;

const ModalButton = styled.div`
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 25px;
  @media (max-width: 1200px) {
     font-size: 13px;
  }
  @media (max-width: 800px) {
     font-size: 12px;
  }
  @media (max-width: 500px) {
     margin: 10px 20px;
  }
`;

const ClearButton = styled.div`
  position: absolute;
  left: 10px;
  top: 15px;
  background: linear-gradient(to right, #12c4d8 0%, #68e5f0 100%);
  border: none;
  padding: 10px 15px 10px 15px;
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  @media (max-width: 1200px) {
     display: none;
  }
`;



let isEqual = function (value, other) {
  let type = Object.prototype.toString.call(value);
  if (type !== Object.prototype.toString.call(other)) return false;
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
  let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;
  let compare = function (item1, item2) {
    let itemType = Object.prototype.toString.call(item1);
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }
    else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }
  return true;
};

const cookies = new Cookies();

class TopLayer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      deleteModal: false,
      currentMessage: '',
      currentTitle: '',
      layerHeight: -800,
      currentMessageOption: 'message',
      titleMessage: '',
      contentMessage: '',
      titleMessageSecond: '',
      contentMessageSecond: '',
      signatureMessage: '',
      percentProgress: 0,
      currentTypMessage: 1,
      valueHour: null,
      valueSecondHour: null,
      valueLawNote: null,
      userMail: '',
      userName: '',
      userSurname: '',
      showPatterns: false,
      showSecondPatterns: false,
      pattern: "",
      secondPattern: "",
      patterns: "",
      secondPatterns: "",
      deliveryTime: new Date().toLocaleString(),
      timeFor2Messages: new Date().toLocaleString(),
      showImportExport: false,
      wantToImport: false,
      file: "",
      importError: "",
      gmailUsername: "",
      gmailPassword: "",
      outcomingMail: "",
      incomingMail: "",
      encryptionWay: "",
      emailUsername: "",
      emailPassword: "",
      spamScoringResult: {
        mark: "",
        percent: "",
        annotation: "",
        isFinished: false
      }
    }
  }

  toggleImportExport = () => {
    this.setState({
      showImportExport: !this.state.showImportExport,
      importError: ""
    })
    $('.App__close-btn').toggle();
  }

  componentWillMount() {
    localStorage.removeItem('deliveryTime');
    localStorage.removeItem('timeFor2Message');
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.tabsData !== nextProps.tabsData) {
      if (this.props.tabsData.secondMessage !== nextProps.tabsData.secondMessage && this.state.showSecondPatterns) {
        return true
      }
      else if (this.props.tabsData.firstMessage !== nextProps.tabsData.firstMessage && this.state.showPatterns) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  componentDidMount() {

    $('.progressBar').hide();

    this.props.setStopInterval(false);

    if (this.props.emailsSuccesfullySentAmount > 0) {
      let interval;
      const stopInterval = () => {
        clearInterval(interval);
      }
      const refreshStatus = () => {
        axios.get('https://contact-hunter.com/pl/poziom_wysylki.php').then(res => {
          console.log(res.data)
          let propsCampaignArray = [];
          this.props.campaignItems.map(item => {
            let email = {
              email: item.email,
              status: item.status
            }
            propsCampaignArray.push(email)
          })

          let responseCampaignArray = [];
          res.data.list.map(item => {
            let email = {
              email: item.email,
              status: item.status
            }
            responseCampaignArray.push(email)
          })
          console.log(propsCampaignArray);
          console.log(responseCampaignArray);
          console.log('arrays are equal or not: ' + isEqual(propsCampaignArray, responseCampaignArray))
          if (isEqual(propsCampaignArray, responseCampaignArray) === false) {
            localStorage.setItem('campaignItems', JSON.stringify(res.data.list));
            this.props.updateCampaign(res.data.list);
          }
          if ((this.props.emailsOpenedAmount === this.props.emailsWantedToSendAmount) && (this.props.emailsOpenedAmount > 0 && this.props.emailsSuccesfullySentAmount > 0)) {
            stopInterval();
          } else if (this.props.stopInterval === true) {
            stopInterval();
          } else {
            let openedMessages = [];
            let moved = [];
            res.data.list.forEach(item => {
              if (item.status === "message_moved") moved.push(item);
            });
            if (res.data.sendMessage === res.data.sendMessageTotal && res.data.sendMessage > 0) {
              res.data.list.forEach(item => {
                if (item.status === "opened") openedMessages.push(item);
              });
            }
            if (this.props.emailsSuccesfullySentAmount !== +res.data.sendMessage) {
              this.props.setEmailsSuccesfullySentAmount(+res.data.sendMessage);
            }
            if (this.props.emailsOpenedAmount !== openedMessages.length) {
              this.props.setEmailsOpenedAmount(openedMessages.length);
            }
            if (this.props.movedMessages !== moved.length) {
              this.props.setMovedMessages(moved.length);
            }
            if (this.props.emailsOpenedAmount > 0) {
              setTimeout(() => {
                this.props.setProgressBarValue(Math.round((this.props.emailsOpenedAmount / this.props.emailsWantedToSendAmount) * 100));
              }, 1200);
            } else if (this.props.emailsSuccesfullySentAmount > 0) {
              this.props.setProgressBarValue(Math.round((this.props.emailsSuccesfullySentAmount / this.props.emailsWantedToSendAmount) * 100));
            } else if (this.props.emailsWantedToSendAmount > 0) {
              this.props.setProgressBarValue(Math.round((this.props.emailsWantedToSendAmount / this.props.maxEmailsAmount) * 100));
            }
          }
        });
      }
      interval = setInterval(refreshStatus, 5000);
    }

    axios.get('https://contact-hunter.com/pl/szablony.php').then(response => {
      this.setState({
        patterns: response.data[0],
        secondPatterns: response.data[1]
      })
    });
  }

  sendSecondTestMessage = () => {

    this.props.handleChangeLoading(true, 'Wysyłanie wiadomości testowej. Proszę czekać');

    const idCamp = makeId(5);

    const data = {
      titleMessageSecond: this.props.tabsData.secondMessageTitle,
      signatureMessage: this.props.tabsData.userSignature.replace(new RegExp('\r?\n', 'g'), '<br />'),
      contentMessageSecond: this.props.tabsData.secondMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      userMail: this.props.tabsData.userMail,
      valueLawNote: this.props.tabsData.lawnote,
      userName: this.props.tabsData.userName,
      userSurname: this.props.tabsData.userSurname,
      idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      gmailUsername: this.props.tabsData.gmailUsername,
      gmailPassword: this.props.tabsData.gmailPassword,
      encryptionWay: this.props.tabsData.encryptionWay,
      emailUsername: this.props.tabsData.emailUsername,
      emailPassword: this.props.tabsData.emailPassword,
      incomingMail: this.props.tabsData.incomingMail,
      outcomingMail: this.props.tabsData.outcomingMail
    }

    fetch('https://contact-hunter.com/pl/send_second_test_message.php', {
      method: "POST",
      body: JSON.stringify(data),
    })

      .then(response => response.json())
      .then(response => {
        this.props.handleChangeLoading(false, '');
        if (response[1] !== "") {
          this.props.handleShowCustomModal(response[1])
        } else {
          this.props.handleShowCustomModal('Wiadomość testowa została wysłana')
        }
      })
      .catch(err => {
        console.log(err);
      })

  }

  sendTestMessage = () => {

    this.props.handleChangeLoading(true, 'Wysyłanie wiadomości testowej. Proszę czekać');

    const {
      titleMessage,
      signatureMessage,
      valueHour,
      contentMessage,
      valueSecondHour,
      valueLawNote,
      userMail,
      userName,
      userSurname,
      gmailUsername,
      gmailPassword,
      encryptionWay,
      emailUsername,
      emailPassword,
      incomingMail,
      outcomingMail
    } = this.state

    const idCamp = makeId(5);

    const data = {
      titleMessage: this.props.tabsData.firstMessageTitle,
      signatureMessage: this.props.tabsData.userSignature.replace(new RegExp('\r?\n', 'g'), '<br />'),
      contentMessage: this.props.tabsData.firstMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      userMail: this.props.tabsData.userMail,
      valueLawNote: this.props.tabsData.lawnote,
      userName: this.props.tabsData.userName,
      userSurname: this.props.tabsData.userSurname,
      idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      gmailUsername: this.props.tabsData.gmailUsername,
      gmailPassword: this.props.tabsData.gmailPassword,
      encryptionWay: this.props.tabsData.encryptionWay,
      emailUsername: this.props.tabsData.emailUsername,
      emailPassword: this.props.tabsData.emailPassword,
      incomingMail: this.props.tabsData.incomingMail,
      outcomingMail: this.props.tabsData.outcomingMail

    }

    fetch('https://contact-hunter.com/pl/send_test_message.php', {
      method: "POST",
      body: JSON.stringify(data),
    })

      .then(response => response.json())
      .then(response => {
        this.props.handleChangeLoading(false, '');
        if (response[1] !== "") {
          this.props.handleShowCustomModal(response[1])
        } else {
          this.props.handleShowCustomModal('Wiadomość testowa została wysłana')
        }
      })
      .catch(err => {
        console.log(err);
      })

  }

  getSpamScoring = () => {

    const {
      titleMessage,
      titleMessageSecond,
      signatureMessage,
      valueHour,
      contentMessage,
      contentMessageSecond,
      valueSecondHour,
      valueLawNote,
      userMail,
      userName,
      userSurname,
      deliveryTime,
      timeFor2Messages,
      gmailUsername,
      gmailPassword,
      encryptionWay,
      emailUsername,
      emailPassword,
      incomingMail,
      outcomingMail
    } = this.state

    const idCamp = makeId(5);

    const data = {
      titleMessage: this.props.tabsData.firstMessageTitle,
      titleMessageSecond: this.props.tabsData.secondMessageTitle,
      signatureMessage: this.props.tabsData.userSignature.replace(new RegExp('\r?\n', 'g'), '<br />'),
      valueLawNote: this.props.tabsData.lawnote,
      contentMessage: this.props.tabsData.firstMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      contentMessageSecond: this.props.tabsData.secondMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      userMail: this.props.tabsData.userMail,
      userName: this.props.tabsData.userName,
      userSurname: this.props.tabsData.userSurname,
      idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      list: [...this.props.campaignItems],
      deliveryTime,
      timeFor2Messages,
      gmailUsername: this.props.tabsData.gmailUsername,
      gmailPassword: this.props.tabsData.gmailPassword,
      encryptionWay: this.props.tabsData.encryptionWay,
      emailUsername: this.props.tabsData.emailUsername,
      emailPassword: this.props.tabsData.emailPassword,
      incomingMail: this.props.tabsData.incomingMail,
      outcomingMail: this.props.tabsData.outcomingMail,
    }

    axios.post('https://contact-hunter.com/pl/spam_scoreing_campaign.php', data).then(res => {
      this.setState({
        spamScoringResult: {
          mark: res.data[0],
          percent: res.data[1],
          annotation: res.data[2],
          isFinished: true
        }
      })
    })
  }

  handleSendMessage = () => {
    if (this.props.tabsData.userMail === "" || this.props.tabsData.userName === "" || this.props.tabsData.userSurname === "") {
      this.props.handleShowCustomModal('Aby wysłać kampanię wprowadź wymagane ustawienia kampanii e-mail');
    } else if (!validator.isEmail(this.props.tabsData.userMail)) {
      this.props.handleShowCustomModal('Aby wysłać kampanię wprowadź poprawny e-mail w ustawieniach kampanii e-mail');
    } else {
      if (this.props.shouldSpamScoringAppearProOption) {
        this.props.setShouldSpamScoringAppear(true);
      } else {
        this.sendMessageForSure();
      }
    }
  }

  edit = () => {
    this.props.setShouldSpamScoringAppear(false);
    this.setState(
      {
        spamScoringResult: {
          mark: "",
          percent: "",
          annotation: "",
          isFinished: false
        }
      }
    )
  }

  sendMessageForSure() {

    this.setState(
      {
        spamScoringResult: {
          mark: "",
          percent: "",
          annotation: "",
          isFinished: false
        }
      }
    )

    this.props.setShouldSpamScoringAppear(false);
    if (localStorage.getItem('text1') && localStorage.getItem('text2')) {
      localStorage.removeItem('text1');
      localStorage.removeItem('text2');
      localStorage.setItem('text1', this.state.contentMessage);
      localStorage.setItem('text2', this.state.contentMessageSecond);
    } else {
      localStorage.setItem('text1', this.state.contentMessage);
      localStorage.setItem('text2', this.state.contentMessageSecond);
    }

    this.props.handleChangeLoading(true, 'Wysyłanie kampanii. Proszę czekać');

    $('.nav-header__btn-send_sendmail').attr('disabled', 'true');

    const {
      titleMessage,
      titleMessageSecond,
      signatureMessage,
      valueHour,
      contentMessage,
      contentMessageSecond,
      valueSecondHour,
      valueLawNote,
      userMail,
      userName,
      userSurname,
      deliveryTime,
      timeFor2Messages,
      gmailUsername,
      gmailPassword,
      encryptionWay,
      emailUsername,
      emailPassword,
      incomingMail,
      outcomingMail
    } = this.state

    const idCamp = makeId(5);

    const data = {
      titleMessage: this.props.tabsData.firstMessageTitle,
      titleMessageSecond: this.props.tabsData.secondMessageTitle,
      signatureMessage: this.props.tabsData.userSignature.replace(new RegExp('\r?\n', 'g'), '<br />'),
      valueLawNote: this.props.tabsData.lawnote,
      contentMessage: this.props.tabsData.firstMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      contentMessageSecond: this.props.tabsData.secondMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      userMail: this.props.tabsData.userMail,
      userName: this.props.tabsData.userName,
      userSurname: this.props.tabsData.userSurname,
      idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      list: [...this.props.campaignItems],
      deliveryTime,
      timeFor2Messages,
      gmailUsername: this.props.tabsData.gmailUsername,
      gmailPassword: this.props.tabsData.gmailPassword,
      encryptionWay: this.props.tabsData.encryptionWay,
      emailUsername: this.props.tabsData.emailUsername,
      emailPassword: this.props.tabsData.emailPassword,
      incomingMail: this.props.tabsData.incomingMail,
      outcomingMail: this.props.tabsData.outcomingMail,
    }

    this.props.clearResults();

    let interval;
    const stopInterval = () => {
      clearInterval(interval);
    }
    let temp = 1;
    const refreshStatus = () => {
      axios.get('https://contact-hunter.com/pl/poziom_wysylki.php').then(res => {
        console.log(res.data)
        let propsCampaignArray = [];
        this.props.campaignItems.map(item => {
          let email = {
            email: item.email,
            status: item.status
          }
          propsCampaignArray.push(email)
        })

        let responseCampaignArray = [];
        res.data.list.map(item => {
          let email = {
            email: item.email,
            status: item.status
          }
          responseCampaignArray.push(email)
        })

        console.log(propsCampaignArray);
        console.log(responseCampaignArray);
        console.log('arrays are equal or not: ' + isEqual(propsCampaignArray, responseCampaignArray))
        if (isEqual(propsCampaignArray, responseCampaignArray) === false) {
          localStorage.setItem('campaignItems', JSON.stringify(res.data.list));
          this.props.updateCampaign(res.data.list);
        }
        if ((this.props.emailsOpenedAmount === this.props.emailsWantedToSendAmount) && (this.props.emailsOpenedAmount > 0 && this.props.emailsSuccesfullySentAmount > 0)) {
          stopInterval();
        }
        else if (this.props.stopInterval === true) {
          stopInterval();
        } else {
          if (temp === 1) {
            this.props.handleChangeLoading(false, "");
            temp++;
          }
          let openedMessages = [];
          let moved = [];
          res.data.list.forEach(item => {
            if (item.status === "message_moved") moved.push(item);
          });
          if (res.data.sendMessage === res.data.sendMessageTotal && res.data.sendMessage > 0) {
            res.data.list.forEach(item => {
              if (item.status === "opened") openedMessages.push(item);
            });
          }
          if (this.props.emailsSuccesfullySentAmount !== +res.data.sendMessage) {
            this.props.setEmailsSuccesfullySentAmount(+res.data.sendMessage);
          }
          if (this.props.emailsOpenedAmount !== openedMessages.length) {
            this.props.setEmailsOpenedAmount(openedMessages.length);
          }
          if (this.props.movedMessages !== moved.length) {
            this.props.setMovedMessages(moved.length);
          }
          if (this.props.emailsOpenedAmount > 0) {
            setTimeout(() => {
              this.props.setProgressBarValue(Math.round((this.props.emailsOpenedAmount / this.props.emailsWantedToSendAmount) * 100));
            }, 1200);
          } else if (this.props.emailsSuccesfullySentAmount > 0) {
            this.props.setProgressBarValue(Math.round((this.props.emailsSuccesfullySentAmount / this.props.emailsWantedToSendAmount) * 100));
          } else if (this.props.emailsWantedToSendAmount > 0) {
            this.props.setProgressBarValue(Math.round((this.props.emailsWantedToSendAmount / this.props.maxEmailsAmount) * 100));
          }
        }
      });
    }
    interval = setInterval(refreshStatus, 5000);

    fetch('https://contact-hunter.com/pl/send_campaign.php', {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(response => {

        if (response[1].length > 0) {
          this.props.handleChangeLoading(false, '');
          this.props.handleShowCustomModal(response[1]);
          setTimeout(() => {
            $('.nav-header__btn-send_sendmail').attr('disabled', null);
          }, 2000);
          stopInterval();
        } else {
          setTimeout(() => {
            $('.nav-header__btn-send_sendmail').attr('disabled', null);
          }, 5000);
        }

      })
      .catch(err => {
        this.props.handleChangeLoading(false, '');
        console.log(err);
      })

  }

  clearCampaign = () => {
    this.setState({
      contentMessage: "",
      contentMessageSecond: "",
      titleMessage: "",
      titleMessageSecond: ""
    })
    this.props.setTabsData({
      firstMessage: "",
      firstMessageTitle: "",
      secondMessage: "",
      secondMessageTitle: ""
    })
  }

  updateCookiesSettings() {
    setTimeout(() => {
      const {
        currentTitle,
        currentTypMessage,
        titleMessage,
        titleMessageSecond,
        signatureMessage,
        valueHour,
        valueSecondHour,
        valueLawNote,
        userMail,
        userName,
        userSurname,
        gmailUsername,
        gmailPassword
      } = this.state;

      const data = {
        titleMessage: currentTypMessage === 1 ? currentTitle : titleMessage,
        titleMessageSecond: currentTypMessage === 2 ? currentTitle : titleMessageSecond,
        signatureMessage,
        valueHour,
        valueSecondHour,
        valueLawNote,
        userMail,
        userName,
        userSurname,
        gmailUsername,
        gmailPassword
      }

      cookies.set('_campaign_user_settings', data)

    }, 0)
  }

  setShowPatterns = (show) => {
    this.setState({
      showPatterns: show
    })
  }
  setShowSecondPatterns = (show) => {
    this.setState({
      showSecondPatterns: show
    })
  }

  changeDeliveryTime = (time) => {
    this.setState({
      deliveryTime: time
    })
  }

  changeTimeFor2Messages = (time) => {
    this.setState({
      timeFor2Messages: time
    })
  }

  import = () => {
    this.setState({
      wantToImport: !this.state.wantToImport
    })
  }

  makeImport = () => {
    let formData = new FormData();
    formData.append("file", this.state.file);
    formData.append('maxAmount', this.props.maxCountNumber);
    formData.append('currentAmount', this.props.currentNumberOfMail);
    axios.post('https://contact-hunter.com/pl/import.php', formData, {
      processData: false,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if (response) {

        this.setState({
          importError: response.data[0]
        })

        if (response.data[0].length !== 0) {
          this.props.handleShowCustomModal(response.data[0]);
        } else {

          let currentItems = this.props.campaignItems;

          let newItems = [];

          response.data[1].list.forEach(item => {
            newItems.push(item);
          });

          let wholeArray = currentItems.concat(newItems);

          function uniqueArray(arr, comp) {
            const unique = arr
              .map(e => e[comp])
              .map((e, i, final) => final.indexOf(e) === i && i)
              .filter(e => arr[e]).map(e => arr[e]);
            return unique;
          }

          this.props.importCampaign(uniqueArray(wholeArray, 'email'));

          if (localStorage.getItem('campaignItems')) {
            let array = JSON.parse(localStorage.getItem('campaignItems'));
            let connectedArray = array.concat(uniqueArray(wholeArray, 'email'));
            function uniqueArray(arr, comp) {
              const unique = arr
                .map(e => e[comp])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter(e => arr[e]).map(e => arr[e]);
              return unique;
            }
            localStorage.setItem('campaignItems', JSON.stringify(uniqueArray(connectedArray, 'email')));
          } else {
            localStorage.setItem('campaignItems', JSON.stringify(uniqueArray(wholeArray, 'email')));
          }
        }
      }
    })
  }

  setFile = (file) => {
    this.setState({
      file
    })
  }

  showDeleteModal = () => {
    this.setState({
      deleteModal: true
    })
  }
  hideDeleteModal = () => {
    this.setState({
      deleteModal: false
    })
  }

  export = () => {

    const idCamp = makeId(5);

    const data = {
      // titleMessage,
      // titleMessageSecond,
      // signatureMessage,
      // valueHour,
      // valueSecondHour,
      // valueLawNote,
      // userMail,
      // userName,
      // userSurname,
      // idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      // list: [...this.props.campaignItems],
      // deliveryTime,
      // timeFor2Messages

      titleMessage: this.props.tabsData.firstMessageTitle,
      titleMessageSecond: this.props.tabsData.secondMessageTitle,
      signatureMessage: this.props.tabsData.userSignature.replace(new RegExp('\r?\n', 'g'), '<br />'),
      valueLawNote: this.props.tabsData.lawnote,
      contentMessage: this.props.tabsData.firstMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      contentMessageSecond: this.props.tabsData.secondMessage.replace(new RegExp('\r?\n', 'g'), '<br />'),
      userMail: this.props.tabsData.userMail,
      userName: this.props.tabsData.userName,
      userSurname: this.props.tabsData.userSurname,
      idCampaign: `camp_${idCamp}_${this.props.valueUniqKey}`,
      list: [...this.props.campaignItems],
      deliveryTime: this.state.deliveryTime,
      timeFor2Messages: this.state.timeFor2Messages,
      gmailUsername: this.props.tabsData.gmailUsername,
      gmailPassword: this.props.tabsData.gmailPassword,
      encryptionWay: this.props.tabsData.encryptionWay,
      emailUsername: this.props.tabsData.emailUsername,
      emailPassword: this.props.tabsData.emailPassword,
      incomingMail: this.props.tabsData.incomingMail,
      outcomingMail: this.props.tabsData.outcomingMail,

    }

    axios.post('https://contact-hunter.com/pl/export.php', data).then(response => {
      window.location.href = response.data;
    });

  }

  rerender = () => {
    this.setState({
      rerender: Math.random()
    })
  }

  render() {
    let styleLayer = {};

    if (!this.props.layerIsShowTop) {
      styleLayer = {
        transform: `translateY(calc(-100% + 70px))`
      }
    } else {
      styleLayer = {
        transform: `translateY(0)`
      }
    }

    const height = window.innerHeight - 300 + 'px';

    const styleScroll = {
      width: '100%',
      height: height,
    }

    return (
      <div className="layer-wrapper layer-wrapper--top" style={styleLayer}>
        {this.props.campaignItems.length > 0 && <CloseButton onClick={() => {
          if (this.props.currentOptionForCloseButton === 'dataPersonal') {
            this.props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-danych/");
            this.props.setTip('Jak szukać leadów B2B po danych osobowych?');
          }
          if (this.props.currentOptionForCloseButton === 'www') {
            this.props.setTip('Jak szukać leadów B2B po domenach firm?');
            this.props.setHref("https://zamow.contact-hunter.com/jak-szukac-leadow-b2b-po-domenach-firm/");
          }
          this.props.showLayerTop();
          $('.progressBar').fadeOut();
          this.props.turnOffLayer();
        }} />}
        {this.state.deleteModal && <Modal>
          Czy chcesz usunąć wszystkie adresy z kampanii e-mailingowej?
         <ButtonWrapper>
            <ModalButton onClick={() => {
              this.props.deleteCampaign();
              this.hideDeleteModal();
            }}>Tak</ModalButton>
            <ModalButton onClick={this.hideDeleteModal}>Nie</ModalButton>
          </ButtonWrapper>
        </Modal>}
        {(this.state.showPatterns || this.state.showSecondPatterns) && <PatternsModal setSecondTopic={this.setSecondTopic} setFirstTopic={this.setFirstTopic} showSecondPatterns={this.state.showSecondPatterns} secondPatterns={this.state.secondPatterns} patterns={this.state.patterns} setShowPatterns={this.setShowPatterns} setShowSecondPatterns={this.setShowSecondPatterns} setPattern={this.setPattern} setSecondPattern={this.setSecondPattern} handleChangeContentMessage={this.handleChangeContentMessage} showPatterns={this.state.showPatterns} />}
        {this.state.showImportExport && <ImportExportModal importError={this.state.importError} makeImport={this.makeImport} setFile={this.setFile} wantToImport={this.state.wantToImport} toggleImportExport={this.toggleImportExport} import={this.import} export={this.export} />}
        {this.props.shouldSpamScoringAppear && <SpamScoring spamScoringResult={this.state.spamScoringResult} getSpamScoring={() => this.getSpamScoring()} sendMessage={() => this.sendMessageForSure()} edit={this.edit} />}
        {this.props.campaignItems.length > 0 && <ClearButtonTopLayer onClick={() => {
          this.setState({
            deleteModal: true
          })
        }}>Usuń wszystkie adresy</ClearButtonTopLayer>}
        <div className="App__container" style={styleScroll}>

          {this.props.campaignItems.length === 0 ?
            <MessageSetting


              results={this.props.campaignItems}

              setShowPatterns={show => this.setShowPatterns(show)}
              setShowSecondPatterns={show => this.setShowSecondPatterns(show)}

              sendSecondTestMessage={this.sendSecondTestMessage}
              clearResults={this.props.clearResults}
              titleMessage={this.state.titleMessage}
              titleMessageSecond={this.state.titleMessageSecond}
              setFirstTopic={this.setFirstTopic}
              setSecondTopic={this.setSecondTopic}
              shouldImportExportAppear={this.props.shouldImportExportAppear}
              shouldSpamScoringAppearProOption={this.props.shouldSpamScoringAppearProOption}
              shouldAccountBePro={this.props.shouldAccountBePro}
              setEmailUsername={this.setEmailUsername}
              setEmailPassword={this.setEmailPassword}
              emailUsername={this.state.emailUsername}
              emailPassword={this.state.emailPassword}
              outcomingMail={this.state.outcomingMail}
              incomingMail={this.state.incomingMail}
              encryptionWay={this.state.encryptionWay}
              setoutcomingMail={this.setoutcomingMail}
              setEncryptionWay={this.setEncryptionWay}
              setincomingMail={this.setincomingMail}
              currentOptionForCloseButton={this.props.currentOptionForCloseButton}
              turnOffLayer={this.props.turnOffLayer}
              sendTestMessage={this.sendTestMessage}
              updateSecondPattern={this.updateSecondPattern}
              gmailUsername={this.state.gmailUsername}
              gmailPassword={this.state.gmailPassword}
              setGmailUsername={this.setGmailUsername}
              setGmailPassword={this.setGmailPassword}
              contentMessage={this.state.contentMessage}
              option={this.props.settingsOptions}
              showLayerTop={() => this.props.showLayerTop()}
              handleChangeMessageOption={(e) => this.handleChangeMessageOption(e)}
              handleChangeTitleMessage={(e) => this.handleChangeTitleMessage(e)}
              handleChangeContentMessage={(e) => this.handleChangeContentMessage(e)}
              handleChangeContentSecondMessage={(e) => this.handleChangeContentSecondMessage(e)}
              handleChangeSignatureMessage={(e) => this.handleChangeSignatureMessage(e)}
              handleChangeNumberMessage={(e) => this.handleChangeNumberMessage(e)}
              handleChangeHour={(value) => this.handleChangeHour(value)}
              handleChangeHourSecondMsg={(value) => this.handleChangeHourSecondMsg(value)}
              handleChangeLawNote={(value) => this.handleChangeLawNote(value)}
              handleChangeUserMail={(value) => this.handleChangeUserMail(value)}
              handleChangeUserName={(value) => this.handleChangeUserName(value)}
              handleChangeUserSurname={(value) => this.handleChangeUserSurname(value)}
              currentMessageOption={this.state.currentMessageOption}
              currentTitle={this.state.currentTitle}
              currentMessage={this.state.currentMessage}
              contentMessageSecond={this.state.contentMessageSecond}
              signatureMessage={this.state.signatureMessage}
              currentTypMessage={this.state.currentTypMessage}
              valueHour={this.state.valueHour}
              valueSecondHour={this.state.valueSecondHour}
              valueLawNote={this.state.valueLawNote}
              userMail={this.state.userMail}
              userName={this.state.userName}
              userSurname={this.state.userSurname}
              showPatterns={this.state.showPatterns}
              setShowPatterns={this.setShowPatterns}
              setPattern={this.setPattern}
              pattern={this.state.pattern}
              updatePattern={this.updatePattern}
              deliveryTime={this.state.deliveryTime}
              timeFor2Messages={this.state.timeFor2Messages}
              changeDeliveryTime={this.changeDeliveryTime}
              changeTimeFor2Messages={this.changeTimeFor2Messages}
            />
            :
            <CampaignItems
              results={this.props.campaignItems}
              removeItemFromCampaign={(id, index) => this.props.removeItemFromCampaign(id, index)}
              sentSuccessfullyEmails={this.props.sentSuccessfullyEmails}
              rerender={this.rerender}


              setShowPatterns={show => this.setShowPatterns(show)}
              setShowSecondPatterns={show => this.setShowSecondPatterns(show)}

              sendSecondTestMessage={this.sendSecondTestMessage}
              clearResults={this.props.clearResults}
              titleMessage={this.state.titleMessage}
              titleMessageSecond={this.state.titleMessageSecond}
              setFirstTopic={this.setFirstTopic}
              setSecondTopic={this.setSecondTopic}
              shouldImportExportAppear={this.props.shouldImportExportAppear}
              shouldSpamScoringAppearProOption={this.props.shouldSpamScoringAppearProOption}
              shouldAccountBePro={this.props.shouldAccountBePro}
              setEmailUsername={this.setEmailUsername}
              setEmailPassword={this.setEmailPassword}
              emailUsername={this.state.emailUsername}
              emailPassword={this.state.emailPassword}
              outcomingMail={this.state.outcomingMail}
              incomingMail={this.state.incomingMail}
              encryptionWay={this.state.encryptionWay}
              setoutcomingMail={this.setoutcomingMail}
              setEncryptionWay={this.setEncryptionWay}
              setincomingMail={this.setincomingMail}
              currentOptionForCloseButton={this.props.currentOptionForCloseButton}
              turnOffLayer={this.props.turnOffLayer}
              sendTestMessage={this.sendTestMessage}
              updateSecondPattern={this.updateSecondPattern}
              gmailUsername={this.state.gmailUsername}
              gmailPassword={this.state.gmailPassword}
              setGmailUsername={this.setGmailUsername}
              setGmailPassword={this.setGmailPassword}
              contentMessage={this.state.contentMessage}
              option={this.props.settingsOptions}
              showLayerTop={() => this.props.showLayerTop()}
              handleChangeMessageOption={(e) => this.handleChangeMessageOption(e)}
              handleChangeTitleMessage={(e) => this.handleChangeTitleMessage(e)}
              handleChangeContentMessage={(e) => this.handleChangeContentMessage(e)}
              handleChangeContentSecondMessage={(e) => this.handleChangeContentSecondMessage(e)}
              handleChangeSignatureMessage={(e) => this.handleChangeSignatureMessage(e)}
              handleChangeNumberMessage={(e) => this.handleChangeNumberMessage(e)}
              handleChangeHour={(value) => this.handleChangeHour(value)}
              handleChangeHourSecondMsg={(value) => this.handleChangeHourSecondMsg(value)}
              handleChangeLawNote={(value) => this.handleChangeLawNote(value)}
              handleChangeUserMail={(value) => this.handleChangeUserMail(value)}
              handleChangeUserName={(value) => this.handleChangeUserName(value)}
              handleChangeUserSurname={(value) => this.handleChangeUserSurname(value)}
              currentMessageOption={this.state.currentMessageOption}
              currentTitle={this.state.currentTitle}
              currentMessage={this.state.currentMessage}
              contentMessageSecond={this.state.contentMessageSecond}
              signatureMessage={this.state.signatureMessage}
              currentTypMessage={this.state.currentTypMessage}
              valueHour={this.state.valueHour}
              valueSecondHour={this.state.valueSecondHour}
              valueLawNote={this.state.valueLawNote}
              userMail={this.state.userMail}
              userName={this.state.userName}
              userSurname={this.state.userSurname}
              showPatterns={this.state.showPatterns}
              setShowPatterns={this.setShowPatterns}
              setPattern={this.setPattern}
              pattern={this.state.pattern}
              updatePattern={this.updatePattern}
              deliveryTime={this.state.deliveryTime}
              timeFor2Messages={this.state.timeFor2Messages}
              changeDeliveryTime={this.changeDeliveryTime}
              changeTimeFor2Messages={this.changeTimeFor2Messages}
            />
          }

        </div>
        <Header
          showDeleteModal={this.showDeleteModal}
          updateCharts={this.props.updateCharts}
          handleShowCustomModal={this.props.handleShowCustomModal}
          shouldImportExportAppear={this.props.shouldImportExportAppear}
          shouldSpamScoringAppearProOption={this.props.shouldSpamScoringAppearProOption}
          shouldAccountBePro={this.props.shouldAccountBePro}
          turnOffLayer={this.props.turnOffLayer}
          isLogged={this.props.isLogged}
          clearCampaign={this.clearCampaign}
          emailsOpened={this.props.emailsOpened}
          resetInterval={this.props.resetInterval}
          currentNumberOfMail={this.props.currentNumberOfMail}
          percentProgress={this.state.percentProgress}
          maxCountNumber={this.props.maxCountNumber}
          showLayerTop={() => this.props.showLayerTop()}
          showLayerBottom={() => this.props.showLayerBottom()}
          layerIsShowTop={this.props.layerIsShowTop}
          isLoggedIn={this.props.isLoggedIn}
          user={this.props.user}
          campaignItems={this.props.campaignItems}
          actionIsAllowed={this.props.isLogged}
          showModal={(e) => this.props.handleShowCustomModal(e)}
          handleSendMessage={() => this.handleSendMessage()}
          sentSuccessfullyEmails={this.props.sentSuccessfullyEmails}
          emailsWantedToSend={this.props.emailsWantedToSend}
          createNewCampaign={this.props.createNewCampaign}
          currentTypMessage={this.state.currentTypMessage}
          sendMessagesAgain={this.sendMessagesAgain}
          toggleImportExport={this.toggleImportExport}
          shouldSecondProgressBar={this.props.shouldSecondProgressBar}
          updateReceivedMessages={this.props.updateReceivedMessages}
          results={this.props.results}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    emailsOpenedAmount: state.emailsOpenedAmount,
    emailsWantedToSendAmount: state.emailsWantedToSendAmount,
    emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    stopInterval: state.stopInterval,
    movedMessages: state.movedMessages,
    shouldSpamScoringAppear: state.shouldSpamScoringAppear,
    tabsData: state.tabsData
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setEmailsOpenedAmount: payload => dispatch(setEmailsOpenedAmount(payload)),
    setEmailsWantedToSendAmount: payload => dispatch(setEmailsWantedToSendAmount(payload)),
    setEmailsSuccesfullySentAmount: payload => dispatch(setEmailsSuccesfullySentAmount(payload)),
    setStopInterval: payload => dispatch(setStopInterval(payload)),
    setShouldSpamScoringAppear: payload => dispatch(setShouldSpamScoringAppear(payload)),
    setMovedMessages: payload => dispatch(setMovedMessages(payload)),
    setTabsData: payload => dispatch(setTabsData(payload)),
    setProgressBarValue: payload => dispatch(setProgressBarValue(payload)),
    setHref: payload => dispatch(setHref(payload)),
    setTip: payload => dispatch(setTip(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(TopLayer)
