export const optionsHour = [
  { value: '12_00', label: '12:00' },
  { value: '18_00', label: '18:00' }
]

export const optionsHourSecondMsg = [
  { value: 'after_1', label: 'po 1 dniu' },
  { value: 'after_2', label: 'po 2 dniach' },
  { value: 'after_3', label: 'po 2 dniach' }
]

export const optionsLawNote = [
  { value: 'yes', label: 'Stopka z adnotacją prawną: TAK' },
  { value: 'no', label: 'Stopka z adnotacją prawną: NIE' },
]
