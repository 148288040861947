import React, { memo } from 'react'

import message from './../../assets/img/ustawienia_wiadomosc.png';
import signature from './../../assets/img/ustawienia_podpis.png';
import settings from './../../assets/img/ustawienia_inne.png';
import { connect } from 'react-redux'
import { setTip, setHref } from '../../redux/reducer'


const SettingOption = memo((props) => {

  let url = null;

  switch (props.dataSearch.type) {
    case 'message':
      url = message;
      break;
    case 'signature':
      url = signature;
      break;
    case 'settings':
      url = settings;
      break;
    default:
      break;
  }

  return (
    <div className={`search-option search-option--${props.numberOfOption}`}>
      <div className={`search-option__circle ${props.dataSearch.type !== props.active ? 'search-option__circle--active' : ''}`}>
        <div className="search-option__circle--shadow"></div>
        <div
          className={`search-option__circle--inner ${props.dataSearch.type !== props.active ? '' : 'clickedImage'}`}
          onClick={(e) => {
            if (props.rerender) {
              props.rerender(Math.random());
            }
            props.handleChangeMessageOption(e.target.dataset.type);
            if (props.numberOfOption === 0) {
              props.setTip('Jak skonfigurować i wysyłać kampanie e-mailingowe?');
              props.setHref('https://zamow.contact-hunter.com/jak-skonfigurowac-i-wysylac-kampanie-e-mailingowe/');
              let e = {
                target: {
                  dataset: {
                    numberMessage: 1
                  }
                }
              }
              props.handleChangeNumberMessage(e.target.dataset.numberMessage);
            }
            if (props.numberOfOption === 1) {
              props.setTip('Czym jest podpis i jak go skonfigurować?');
              props.setHref("https://zamow.contact-hunter.com/czym-jest-podpis-i-jak-go-skonfigurowac/");
            }
            if (props.numberOfOption === 2) {
              props.setTip('Jak skonfigurować i wysyłać kampanie e-mailingowe?');
              props.setHref('https://zamow.contact-hunter.com/jak-skonfigurowac-i-wysylac-kampanie-e-mailingowe/');
            }
          }}
          data-type={props.dataSearch.type}
        >
          <img
            data-type={props.dataSearch.type}
            src={url} alt="" />
        </div>
      </div>
      <p className="search-option__text text-white text-center ">{props.dataSearch.description}</p>
    </div>
  );
})

const mapStateToProps = state => {
  return {
    tip: state.tip,
    href: state.href
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(SettingOption)

