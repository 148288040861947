import React, { useState, useEffect, memo } from 'react'
import styled, { css } from 'styled-components'

const GlobalWrapper = styled.div`
     ${({ flex }) => flex && css`
        display: flex;
        flex-direction: ${({ flexColumn }) => flexColumn ? 'column' : 'initial'};   
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: ${({ flexWrap }) => flexWrap ? 'wrap' : 'initial'};   
    `}
    ${({ center }) => center && css`
        justify-content: center;
        align-items: center;
    `}
    white-space:nowrap;
`;

const StickySliderWrapper = styled(GlobalWrapper)`
    position: relative;
    height: ${({ height }) => height};   
    width: 600px;
    position: fixed;
    right: -2px;
    top: ${({ top }) => top};   
    z-index: 999;
    filter:drop-shadow(0px 0px 3px black);
    transition: 1s max-width ease-in-out;
    @media (max-width: 768px) {
        height: 180px;   
        width: 400px;
    }
    @media (max-width: 460px) {
        width: 220px;
    }
`;

const StyledStickySliderContent = styled(GlobalWrapper)`
    height:100%;
    width: 100%;
    background: #11C4D7;
    top: ${({ top }) => top};
    border-bottom-left-radius: 15px;
    padding-left: ${({ paddingLeft }) => paddingLeft};
    
    iframe{
        width: 90%;
        height: 90%;
        transition: 1s opacity ease-in-out;
        font-size: 10px;
    }
    
    @media (max-width: 768px) {
        padding-left: 25px;
        iframe{
        width: 100%;
        height: 90%;
    }
    }
    @media (max-width: 500px) {
        padding-left: 15px;
    }

`;

const StyledStickySliderDescription = styled.div`
    position: absolute;
    height: 80%;
    width: 40px;
    top: 0;
    left: -39px;
    font-weight: bold;
    background: #11C4D7;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: -10px 2px -2px 10px black;
    text-transform: uppercase;
    color: white;
    transform: rotate(180deg);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;

    :hover{
        cursor:pointer;
    }

    @media (max-width: 768px) {
        width: 40px;
        font-size: 10px;
    }
`;

const StyledStickySliderText = styled.div`
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    
    @media (max-width: 768px) {
        font-size: 10px;
        white-space: pre-wrap;
    }
`;

const StickySliderBonusKeyWrapper = styled(GlobalWrapper)``;

const StyledStickySliderBonusKeyText = styled.div`
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

const StyledStickySliderBonusKey = styled.div`
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const StyledStickySliderButton = styled(GlobalWrapper)`
    color: #11C4D7;
    text-transform: uppercase;
    font-weight: bold;
    background: white;
    padding: 20px 30px;
    text-decoration: none;

    @media (max-width: 768px) {
        font-size: 12px;
        padding: 10px 12px;
    }
`;

const StickySlider = memo((props) => {
    const [shouldSlideIn1, setShouldSlideIn1] = useState(false);
    const [shouldSlideIn2, setShouldSlideIn2] = useState(false);
    const [shouldPlayVideo, setShouldPlayVideo] = useState(false);
    const [shouldVideoFadeIn, setShouldVideoFadeIn] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, [])
    useEffect(() => {
        if (isMounted) {
            setTimeout(() => {
                if (isMounted) {
                    setShouldSlideIn2(!shouldSlideIn2);
                }
            }, 45000);
        }
    }, [isMounted])
    useEffect(() => {
        if (props.visitsOnPage !== undefined && props.visitsOnPage < 2) {
            if (!shouldSlideIn1) {
                setTimeout(() => {
                    if (isMounted) {
                        setShouldSlideIn1(true);
                    }
                }, 5000);
                setTimeout(() => {
                    if (isMounted) {
                        setShouldPlayVideo(true);
                        setShouldVideoFadeIn(true);
                    }
                }, 5600);
            }
        }
    }, [props.visitsOnPage])
    return (
        <>
            <StickySliderWrapper style={shouldSlideIn1 ? { maxWidth: '800px' } : { maxWidth: '0px' }} top="15%" height="240px">
                <StyledStickySliderContent flex flexColumn center>
                    <iframe className="ytPlayer" title="ytPlayer" style={shouldVideoFadeIn ? { opacity: '1' } : { opacity: '0' }} src={`https://www.youtube.com/embed/mityDg5y1P4?${shouldPlayVideo ? `autoplay=1` : `autoplay=0`}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </StyledStickySliderContent>
                <StyledStickySliderDescription onClick={() => {
                    if (!shouldSlideIn1) {
                        setShouldSlideIn1(!shouldSlideIn1);
                        setShouldPlayVideo(!shouldPlayVideo);
                        setTimeout(() => {
                            setShouldVideoFadeIn(!shouldVideoFadeIn);
                        }, 600);
                    } else {
                        setShouldVideoFadeIn(!shouldVideoFadeIn);
                        setShouldPlayVideo(!shouldPlayVideo);
                        setTimeout(() => {
                            setShouldSlideIn1(!shouldSlideIn1);
                        }, 500);
                    }
                }}>Jak to działa?</StyledStickySliderDescription>
            </StickySliderWrapper>
            <StickySliderWrapper style={shouldSlideIn2 ? { maxWidth: '800px' } : { maxWidth: '0px' }} top="52%" height="260px">
                <StyledStickySliderContent flex flexColumn paddingLeft="50px">
                    <StyledStickySliderText>Korzystaj z konta premium -10% taniej</StyledStickySliderText>
                    <StickySliderBonusKeyWrapper flex flexColumn>
                        <StyledStickySliderBonusKeyText>Twój kod rabatowy:</StyledStickySliderBonusKeyText>
                        <StyledStickySliderBonusKey>xshunter43287</StyledStickySliderBonusKey>
                    </StickySliderBonusKeyWrapper>
                    <StyledStickySliderButton as="a" href="https://zamow.contact-hunter.com" target="_blank" rel="noopener noreferrer">Zamów konto premium</StyledStickySliderButton>
                </StyledStickySliderContent>
                <StyledStickySliderDescription onClick={() => setShouldSlideIn2(!shouldSlideIn2)}>Kup konto premium</StyledStickySliderDescription>
            </StickySliderWrapper>
        </>
    )
})

export default StickySlider
