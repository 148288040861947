import React, { useEffect, useState, memo } from 'react'
import Select from 'react-select-plus';
import { optionsLawNote } from '../Utilities/optionsForSelect'
import Calendar from '../Time/Calendar'
import ReactHtmlParser from 'react-html-parser'
import $ from 'jquery'
import { connect } from 'react-redux'
import { setTip, setHref, setTabsData, setBlockFirstPatterns, setBlockSecondPatterns } from '../../redux/reducer'

var setCookie = function (name, value, days) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
  }
  else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

const encryptionOptions = [
  { value: 'ssl', label: 'Sposób szyfrowania: SSL' },
  { value: 'tls', label: 'Sposób szyfrowania: TLS' },
  { value: 'none', label: 'Sposób szyfrowania: Brak' },
]

const Tabs = memo((props) => {
  const [properGmailError, setProperGmailError] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [timeFor2Message, setTimeFor2Message] = useState();
  const [emailsOption, setEmailsOption] = useState(1);
  const setDeliveryTimeFunction = (toLocaleString, dateObject) => {
    setDeliveryTime(toLocaleString);
    localStorage.setItem('deliveryTime', dateObject);
  }
  const setTimeFor2MessageFunction = (toLocaleString, dateObject) => {
    setTimeFor2Message(toLocaleString);
    localStorage.setItem('timeFor2Message', dateObject);
  }
  useEffect(() => {
    if (deliveryTime) {
      props.changeDeliveryTime(deliveryTime);
    }
    if (timeFor2Message) {
      props.changeTimeFor2Messages(timeFor2Message)
    }
  }, [deliveryTime, timeFor2Message])
  useEffect(() => {
    let user = getCookie("remember");
    if (user !== "") {
    } else {
      localStorage.clear();
      setCookie("remember", 4, 30);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem('emailUsername')) props.setTabsData(localStorage.getItem('emailUsername'));
    if (localStorage.getItem('incomingMail')) props.setTabsData(localStorage.getItem('incomingMail'));
    if (localStorage.getItem('outcomingMail')) props.setTabsData(localStorage.getItem('outcomingMail'));
    if (localStorage.getItem('encryptionWay')) props.setTabsData(JSON.parse(localStorage.getItem('encryptionWay')));
  }, [])
  return (
    <div className="tabs-wraper">
      <div className="tabs-wraper__title ">
        {props.currentMessageOption === 'message' && (
          <div>
            <button
              onClick={(e) => {
                props.handleChangeNumberMessage(parseInt(e.target.dataset.numberMessage, 10));
                props.setTip('Jak skonfigurować i wysyłać kampanie e-mailingowe?');
                props.setHref('https://zamow.contact-hunter.com/jak-skonfigurowac-i-wysylac-kampanie-e-mailingowe/');
              }}
              data-number-message='1'
              className={`tabs-wraper__btn tabs-wraper__btn--tab ${props.currentTypMessage === 1 ? 'tabs-wraper__btn--tab-active' : ''}`}
            >Pierwsza wiadomość</button>
            <button
              onClick={(e) => {
                props.handleChangeNumberMessage(parseInt(e.target.dataset.numberMessage, 10));
                props.setTip('Czym jest kolejna wiadomość i jak ją skonfigurować?');
                props.setHref("https://zamow.contact-hunter.com/czym-jest-kolejna-wiadomosc-i-jak-ja-skonfigurowac/");
              }}
              data-number-message='2'
              className={`tabs-wraper__btn tabs-wraper__btn--tab ${props.currentTypMessage === 2 ? 'tabs-wraper__btn--tab-active' : ''}`}
            >Kolejna wiadomość</button>
          </div>
        )}
        {props.currentMessageOption === 'settings' && (
          <div>
            <button
              onClick={() => {
                setEmailsOption(1);
                props.setTip('Jak skonfigurować i wysyłać kampanie e-mailingowe?');
                props.setHref('https://zamow.contact-hunter.com/jak-skonfigurowac-i-wysylac-kampanie-e-mailingowe/');
              }}
              data-number-message='1'
              className={`tabs-wraper__btn tabs-wraper__btn--tab ${emailsOption === 1 ? 'tabs-wraper__btn--tab-active' : ''}`}
            >Ogólne</button>
            {props.shouldAccountBePro ?
              <button
                onClick={() => {
                  setEmailsOption(2);
                  props.setTip('Jak wysyłać masowo e-mailingi przez zwykłą skrzynkę gmail?');
                  props.setHref("https://zamow.contact-hunter.com/jak-wysylac-masowo-e-mailingi-przez-zwykla-skrzynke-gmail/");
                }}
                data-number-message='2'
                className={`tabs-wraper__btn tabs-wraper__btn--tab ${emailsOption === 2 ? 'tabs-wraper__btn--tab-active' : ''}`}
              >Ustawienia skrzynki e-mail</button>
              :
              <button
                onClick={() => {
                  setEmailsOption(2);
                  props.setTip('Jak wysyłać masowo e-mailingi przez zwykłą skrzynkę gmail?');
                  props.setHref("https://zamow.contact-hunter.com/jak-wysylac-masowo-e-mailingi-przez-zwykla-skrzynke-gmail/");
                }}
                data-number-message='2'
                className={`tabs-wraper__btn tabs-wraper__btn--tab ${emailsOption === 2 ? 'tabs-wraper__btn--tab-active' : ''}`}
              >Ustawienia skrzynki gmail</button>
            }
          </div>
        )}
      </div>
      {props.currentMessageOption === 'message' && (
        <div className="tabs-wraper__content">
          <div className="tab-message">
            <div className="text-area-wrapper">
              <div className="vocative-wrapper">
                {props.currentTypMessage === 1 &&
                  <>
                    <button className="vocative" disabled={props.tabsData.firstMessage.length > 0 ? null : true} onClick={() => {
                      let start = $('textarea#messageContent').prop('selectionStart');
                      let end = $('textarea#messageContent').prop('selectionEnd');
                      let currentContent = $('textarea#messageContent').val();
                      let textBefore = currentContent.substring(0, start);
                      let textAfter = currentContent.substring(end, currentContent.length);
                      $('textarea#messageContent').val(textBefore + "[WOŁACZ IMIENNY]" + textAfter);
                      let updatedPattern = $('textarea#messageContent').val();
                      props.setTabsData({
                        firstMessage: updatedPattern
                      })
                    }}>Wołacz imienny</button>
                    <button className="vocative" disabled={props.tabsData.firstMessage.length > 0 ? null : true} onClick={() => {
                      let start = $('textarea#messageContent').prop('selectionStart');
                      let end = $('textarea#messageContent').prop('selectionEnd');
                      let currentContent = $('textarea#messageContent').val();
                      let textBefore = currentContent.substring(0, start);
                      let textAfter = currentContent.substring(end, currentContent.length);
                      $('textarea#messageContent').val(textBefore + "[NAZWA FIRMY]" + textAfter);
                      let updatedPattern = $('textarea#messageContent').val();
                      props.setTabsData({
                        firstMessage: updatedPattern
                      })
                    }}>Nazwa firmy</button>
                  </>
                }
                {props.currentTypMessage === 2 &&
                  <>
                    <button className="vocative" disabled={props.tabsData.secondMessage.length > 0 ? null : true} onClick={() => {
                      let start = $('textarea#messageContent2').prop('selectionStart');
                      let end = $('textarea#messageContent2').prop('selectionEnd');
                      let currentContent = $('textarea#messageContent2').val();
                      let textBefore = currentContent.substring(0, start);
                      let textAfter = currentContent.substring(end, currentContent.length);
                      $('textarea#messageContent2').val(textBefore + "[WOŁACZ IMIENNY]" + textAfter);
                      let updatedPattern = $('textarea#messageContent2').val();
                      props.setTabsData({
                        secondMessage: updatedPattern
                      })
                    }}>Wołacz imienny</button>
                    <button className="vocative" disabled={props.tabsData.secondMessage.length > 0 ? null : true} onClick={() => {
                      let start = $('textarea#messageContent2').prop('selectionStart');
                      let end = $('textarea#messageContent2').prop('selectionEnd');
                      let currentContent = $('textarea#messageContent2').val();
                      let textBefore = currentContent.substring(0, start);
                      let textAfter = currentContent.substring(end, currentContent.length);
                      $('textarea#messageContent2').val(textBefore + "[NAZWA FIRMY]" + textAfter);
                      let updatedPattern = $('textarea#messageContent2').val();
                      props.setTabsData({
                        secondMessage: updatedPattern
                      })
                    }}>Nazwa firmy</button>
                  </>
                }
              </div>
              {props.currentTypMessage === 1 &&
                <textarea
                  id="messageContent"
                  value={ReactHtmlParser(props.tabsData.firstMessage)}
                  onChange={(e) => {
                    if (!props.tabsData.firstMessage || e.target.value.length === 0) {
                      props.setShowPatterns(true);
                      props.setShowSecondPatterns(false);
                    }
                    props.setTabsData({
                      firstMessage: e.target.value
                    })
                  }}
                  onPaste={() => {
                    props.setShowPatterns(false)
                    props.setShowSecondPatterns(false)
                    props.setShowPatterns(false)
                    props.setShowSecondPatterns(false)
                    props.setBlockFirstPatterns(true)
                  }}
                  className="tab-message__text tab-message__text--main" placeholder="Treść wiadomości"></textarea>
              }
              {props.currentTypMessage === 2 &&
                <textarea
                  id="messageContent2"
                  value={ReactHtmlParser(props.tabsData.secondMessage)}
                  onChange={(e) => {
                    if (!props.tabsData.secondMessage || e.target.value.length === 0) {
                      props.setShowSecondPatterns(true);
                      props.setShowPatterns(false);
                    }
                    props.setTabsData({
                      secondMessage: e.target.value
                    })
                  }}
                  onPaste={() => {
                    props.setShowPatterns(false)
                    props.setShowSecondPatterns(false)
                    props.setShowPatterns(false)
                    props.setShowSecondPatterns(false)
                    props.setBlockSecondPatterns(true)
                  }}
                  className="tab-message__text tab-message__text--main" placeholder="Treść wiadomości"></textarea>
              }
            </div>
            {props.currentTypMessage === 1 &&
              <textarea
                value={ReactHtmlParser(props.tabsData.firstMessageTitle)}
                onChange={(e) => props.setTabsData({
                  firstMessageTitle: e.target.value
                })}
                className="tab-message__text tab-message__text--title"
                placeholder="Temat wiadomości"></textarea>
            }
            {props.currentTypMessage === 2 &&
              <textarea
                value={ReactHtmlParser(props.tabsData.secondMessageTitle)}
                onChange={(e) => props.setTabsData({
                  secondMessageTitle: e.target.value
                })}
                className="tab-message__text tab-message__text--title"
                placeholder="Temat wiadomości"></textarea>
            }
          </div>
        </div>
      )}
      {props.currentMessageOption === 'signature' && (
        <div className="tabs-wraper__content">
          <div className="tab-message signature">
            <textarea
              value={props.tabsData.userSignature}
              onChange={(e) => props.setTabsData({
                userSignature: e.target.value
              })}
              className="tab-message__text"
              placeholder="Twój podpis"></textarea>
          </div>
        </div>
      )}
      {props.currentMessageOption === 'settings' && (
        <>
          {emailsOption === 1 ?
            <div className="tabs-settings tabs-wraper__content">
              <div className="tabs-settings__wrapper">
                <div className="calendarWrapper">
                  <label>Czas dostarczenia pierwszej wiadomości</label>
                  <Calendar time1={true} function={setDeliveryTimeFunction} />
                </div>
                <div className="calendarWrapper">
                  <label>Czas dostarczenia kolejnej wiadomości</label>
                  <Calendar time2={true} function={setTimeFor2MessageFunction} />
                </div>
              </div>
              <div className="tabs-settings__wrapper labels-wrapper" >
                <label htmlFor="surname_person" className="input-label label-left">Twój adres e-mail (wymagany)</label>
                <label htmlFor="surname_person" className="input-label label-right">Stopka z adnotacją prawną</label>
                <label htmlFor="hidden" className="label-hidden">hidden</label>
              </div>
              <div className="tabs-settings__wrapper">
                <input
                  value={props.tabsData.userMail}
                  onChange={(e) => props.setTabsData({
                    userMail: e.target.value
                  })}
                  className="tabs-settings__input text-small"
                  placeholder="Twój adres e-mail"
                  type="email" />
                <Select
                  name="select-law-note-option"
                  value={props.tabsData.lawnote}
                  onChange={(e) => props.setTabsData({
                    lawnote: e
                  })}
                  options={optionsLawNote}
                  searchable={false}
                  className={'tabs-settings__select text-small'}
                  clearable={false}
                  placeholder='Stopka z adnotacją prawną'
                />
              </div>
              <div className="tabs-settings__wrapper labels-wrapper" >
                <label htmlFor="surname_person" className="input-label label-left">Imię (wymagane)</label>
                <label htmlFor="surname_person" className="input-label label-right">Nazwisko (wymagane)</label>
                <label htmlFor="hidden" className="label-hidden">hidden</label>
              </div>
              <div className="tabs-settings__wrapper">
                <input
                  value={props.tabsData.userName}
                  onChange={(e) => props.setTabsData({
                    userName: e.target.value
                  })}
                  className="tabs-settings__input text-small"
                  placeholder="Imię"
                  type="text" />
                <input
                  value={props.tabsData.userSurname}
                  onChange={(e) => props.setTabsData({
                    userSurname: e.target.value
                  })}
                  className="tabs-settings__input text-small"
                  placeholder="Nazwisko"
                  type="text" />
              </div>
            </div>
            :
            <>
              <form autoComplete="off" className="gmail-container">
                {properGmailError && <div className="gmailError" style={{ color: "white", fontSize: '10px', marginBottom: '0' }}>{properGmailError}</div>}
                {props.shouldAccountBePro ?
                  <>
                    <div className="inputs-container labels-wrapper" >
                      <label htmlFor="surname_person" className="input-label label-left" style={{ marginLeft: 10 }}>Adres skrzynki e-mail</label>
                      <label htmlFor="surname_person" className="input-label label-right" style={{ marginLeft: 10 }}>Hasło skrzynki e-mail</label>
                      <label htmlFor="hidden" className="label-hidden">hidden</label>
                    </div>
                    <div className="inputs-container">
                      <input
                        value={props.tabsData.emailUsername}
                        onChange={(e) => props.setTabsData({
                          emailUsername: e.target.value
                        })}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Adres skrzynki e-mail"
                        type="text" />
                      <input
                        value={props.tabsData.emailPassword}
                        onChange={(e) => props.setTabsData({
                          emailPassword: e.target.value
                        })}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Hasło skrzynki e-mail"
                        type="password" />
                    </div>
                    <div className="inputs-container labels-wrapper" >
                      <label htmlFor="surname_person" className="input-label label-left" style={{ marginLeft: 10 }}>Adres poczty przychodzącej</label>
                      <label htmlFor="surname_person" className="input-label label-right" style={{ marginLeft: 10 }}>Adres poczty wychodzącej</label>
                      <label htmlFor="hidden" className="label-hidden">hidden</label>
                    </div>
                    <div className="inputs-container">
                      <input
                        value={props.tabsData.incomingMail}
                        onChange={(e) => props.setTabsData({
                          incomingMail: e.target.value
                        })}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Adres poczty przychodzącej"
                        type="text" />
                      <input
                        value={props.tabsData.outcomingMail}
                        onChange={(e) => props.setTabsData({
                          outcomingMail: e.target.value
                        })}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Adres poczty wychodzącej"
                        type="text" />
                    </div>
                    <div className="inputs-container labels-wrapper" >
                      <label htmlFor="surname_person" className="input-label label-left" style={{ marginLeft: 10 }}>Sposób szyfrowania</label>
                      <label htmlFor="hidden" className="label-hidden">hidden</label>
                    </div>
                    <div className="inputs-container">
                      <Select
                        style={{ width: '100%' }}
                        name="select-law-note-option"
                        value={props.tabsData.encryptionWay}
                        onChange={(e) => props.setTabsData({
                          encryptionWay: e
                        })}
                        options={encryptionOptions}
                        searchable={false}
                        className={'tabs-settings__select text-small emailselect'}
                        clearable={false}
                        placeholder='Sposób szyfrowania'
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className="inputs-container labels-wrapper" >
                      <label htmlFor="surname_person" className="input-label label-left" style={{ marginLeft: 10 }}>Adres skrzynki gmail</label>
                      <label htmlFor="surname_person" className="input-label label-right" style={{ marginLeft: 10 }}>Hasło skrzynki gmail</label>
                      <label htmlFor="hidden" className="label-hidden">hidden</label>
                    </div>
                    <div className="inputs-container">
                      <input
                        value={props.tabsData.gmailUsername}
                        onChange={(e) => {
                          props.setTabsData({
                            gmailUsername: e.target.value
                          })
                          if (!e.target.value.endsWith('@gmail.com')) {
                            setProperGmailError('Wprowadzony adres e-mail jest nieprawidłowy');
                          }
                          if (e.target.value.length <= 10) {
                            setProperGmailError('Wprowadzony adres e-mail jest nieprawidłowy');
                          }
                          if (e.target.value.length >= 11 && e.target.value.endsWith('@gmail.com')) {
                            setProperGmailError('');
                          }
                        }}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Adres skrzynki Gmail"
                        type="email" />
                      <input
                        value={props.tabsData.gmailPassword}
                        onChange={(e) => props.setTabsData({
                          gmailPassword: e.target.value
                        })}
                        className="tabs-settings__input text-small"
                        style={{ flex: '0.9', margin: '5px 5px' }}
                        placeholder="Hasło skrzynki Gmail"
                        type="password" />
                    </div>
                  </>
                }
              </form>
            </>
          }
        </>
      )
      }
    </div >
  )
})

const mapStateToProps = state => {
  return {
    tip: state.tip,
    href: state.href,
    tabsData: state.tabsData
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload)),
    setTabsData: payload => dispatch(setTabsData(payload)),
    setBlockFirstPatterns: payload => dispatch(setBlockFirstPatterns(payload)),
    setBlockSecondPatterns: payload => dispatch(setBlockSecondPatterns(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(Tabs)

