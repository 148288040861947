import React, { useEffect, useState, memo } from 'react'
import { DateTimePicker } from 'react-widgets'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import 'moment/locale/pl'
Moment.locale('pl')
momentLocalizer()

const Calendar = memo((props) => {
    const [initialDate, setInitialDate] = useState(new Date());
    const handleDateChange = (newDate) => {
        setInitialDate(newDate);
        props.function(newDate.toLocaleString(), newDate);
    }
    useEffect(() => {
        if (props.time1 && localStorage.getItem('deliveryTime')) {
            setInitialDate(new Date(localStorage.getItem('deliveryTime')));
        }
        if (props.time2 && localStorage.getItem('timeFor2Message')) {
            setInitialDate(new Date(localStorage.getItem('timeFor2Message')));
        }
    }, [])
    return (
        <DateTimePicker
            value={initialDate}
            onChange={handleDateChange}
        />
    )
})

export default Calendar
