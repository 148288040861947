import React, { useState, useEffect, memo } from 'react'

import penImg from '../../assets/img/pen.png'
import itemImag from './../../assets/img/szukaj_osoby.png';
import removeItem from './../../assets/img/usun_email.png';
import sendMsg from './../../assets/img/wiadomosc_wyslana.png'
import notOpenMsg from './../../assets/img/wiadomosc_nieodczytana.png'
import openMsg from './../../assets/img/wiadomosc_odczytana.png'
import { connect } from 'react-redux'
import styled, { keyframes, css } from 'styled-components'
import axios from 'axios'
import { setCurrentItem } from '../../redux/reducer'

const slideIn = keyframes`
  0%{
    max-height: 0px;
  }
  100%{
    max-height: 200px;
    padding: 10px 20px;
  }
`;
const slideOut = keyframes`
  0%{
    max-height: 200px;
    padding: 10px 20px;
  }
  100%{
    max-height: 0px;
    padding: 0px 20px;
  }
`;

const ItemWrapper = styled.div`
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin: 6.5px 10px;
`;
const EditField = styled.div`

  background: #11C4D7;
  width: 100%;
  display: 'flex';
  max-height: 0;
  overflow: hidden;
  padding: 0px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  ${({ shouldSlideIn }) => shouldSlideIn === true ? css`
    /* animation: 0.7s ${slideIn} ease-in-out both; */
    max-height: 127.4px;
    height: 127.4px;
    padding: 10px 20px;
    ` : shouldSlideIn === false ? css`
    /* animation: 0.6s ${slideOut} ease-in-out both; */
    max-height: 0px;
    height: 0px;
    padding: 0px 10px;
  ` : null}
`;
const EditFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ shouldSlideIn }) => shouldSlideIn === true ? css`
    height: 127.4px;
    ` : shouldSlideIn === false ? css`
    height: 0px;
` : null}
`;

const statusInfo = {
  to_send: {
    img: sendMsg,
    text: 'wiadomość do<br/> wysłania '
  },
  "opened": {
    img: openMsg,
    text: 'wiadomość </br> odczytana'
  },
  "not_open": {
    img: notOpenMsg,
    text: 'wiadomość <br/> nieodczytana '
  },
  "sending": {
    img: sendMsg,
    text: 'trwa wysyłanie <br/> wiadomości'
  },
  "not_sended": {
    img: '',
    text: 'wiadomość nie wysłana'
  },
  "sended": {
    img: sendMsg,
    text: 'wiadomość <br/>wysłana'
  },
  "message_moved": {
    img: sendMsg,
    text: 'wiadomość <br/>do wysłania'
  }
}


const CampaignItem = memo((props) => {
  const [shouldSlideIn, setShouldSlideIn] = useState(undefined)
  const [shouldRerender, setShouldRerender] = useState("");
  const [icon, setIcon] = useState(itemImag)
  useEffect(() => {
    if (props.data.height === props.mySize + 130) {
      setShouldSlideIn(true);
    }
  }, [])
  useEffect(() => {
    setShouldRerender(Math.random())
  }, [props.rerender])
  return (
    <ItemWrapper>
      <div className="results-item" style={{ borderRadius: 0, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', marginBottom: '0px', marginTop: '0px', boxShadow: 'none' }}>
        <div className="results-item__info">
          <div className="results-item__circle" onMouseEnter={() => {
            if (shouldSlideIn === false || shouldSlideIn === undefined) {
              setIcon(penImg)
            }
          }} onMouseLeave={() => {
            if (shouldSlideIn === false || shouldSlideIn === undefined) {
              setIcon(itemImag)
            }
          }} style={{ cursor: 'pointer' }} onClick={() => {
            setShouldSlideIn(shouldSlideIn => !shouldSlideIn);
            props.setCurrentItem(props.index)
            if (!shouldSlideIn) {
              props.setRerender(Math.random())
              setIcon(penImg);
              axios.post('https://contact-hunter.com/pl/get_data_edit.php', {
                emailAddress: [props.data.email]
              }).then(res => {
                props.setName(res.data[0][0]);
                props.setSurname(res.data[0][1]);
                props.setPosition(res.data[0][2]);
                props.setCompanyName(res.data[0][3]);
                setShouldRerender(Math.random());
              })
            } else {
              setIcon(itemImag)
              // setTimeout(() => {
              props.setRerender(Math.random())
              // }, 600);
              axios.post('https://contact-hunter.com/pl/set_data_edit.php', {
                emailAddress: props.data.email,
                imie: props.data.name,
                nazwisko: props.data.surname,
                stanowisko: props.data.position,
                firma: props.data.companyName
              })
            }
          }}>
            <img src={icon} alt="" />
          </div>
          <div>
            <p className="results-item__details--name text-dark text-bold">{props.data.name} {props.data.surname} </p>
            <p className="results-item__details--name text-dark text-bold">{props.data.position} </p>
            <p className="results-item__details--name text-dark text-bold">{props.data.companyName} </p>
            <p className="text-bold">{props.data.email}</p>
          </div>
        </div>
        <div className="results-item__wrapper-action">
          <div className="results-item__action text-right">
            <p dangerouslySetInnerHTML={{ __html: statusInfo[props.data.status].text }} ></p>
            <img
              className={`results-item__action-img  ${(props.data.status === 'to_send' || props.data.status === 'sending') ? 'results-item__action-img--disable' : ''}`}
              src={statusInfo[props.data.status].img} alt="" />
          </div>

          {parseInt(props.emailsSuccesfullySentAmount) === 0 && props.emailsOpenedAmount === 0 && props.movedMessages === 0 ?
            <div className="results-item__action text-right">
              <p>usuń osobę z<br />kampanii </p>
              <img
                onClick={() => props.removeItemFromCampaign(props.data.email, props.index)}
                className="results-item__action-img"
                src={removeItem} alt="" />
            </div>
            :
            null
          }

        </div>
      </div>
      {/* <EditFieldWrapper shouldSlideIn={shouldSlideIn}> */}

      <EditField shouldSlideIn={shouldSlideIn}>
        <div className="tabs-settings__wrapper labels-wrapper" >
          <label htmlFor="surname_person" className="input-label label-left">Imię</label>
          <label htmlFor="surname_person" className="input-label label-right">Nazwisko</label>
          <label htmlFor="hidden" className="label-hidden">hidden</label>
        </div>
        <div className="tabs-settings__wrapper" style={{ marginBottom: '5px' }}>
          <input
            style={{ minHeight: '28px' }}
            value={props.data.name}
            placeholder="Imię"
            onChange={e => {
              props.setName(e.target.value);
              setShouldRerender(Math.random());
            }}
            className="tabs-settings__input text-small"
            type="text" />
          <input
            style={{ minHeight: '28px' }}
            value={props.data.surname}
            placeholder="Nazwisko"
            onChange={e => {
              props.setSurname(e.target.value);
              setShouldRerender(Math.random());
            }}
            className="tabs-settings__input text-small"
            type="text" />
        </div>
        <div className="tabs-settings__wrapper labels-wrapper">
          <label htmlFor="surname_person" className="input-label label-left">Stanowisko</label>
          <label htmlFor="surname_person" className="input-label label-right">Nazwa firmy</label>
          <label htmlFor="hidden" className="label-hidden">hidden</label>
        </div>
        <div className="tabs-settings__wrapper">
          <input
            style={{ minHeight: '28px' }}
            value={props.data.position}
            placeholder="Stanowisko"
            onChange={e => {
              props.setPosition(e.target.value);
              setShouldRerender(Math.random());
            }}
            className="tabs-settings__input text-small"
            type="text" />
          <input
            style={{ minHeight: '28px' }}
            value={props.data.companyName}
            placeholder="Nazwa firmy"
            onChange={e => {
              props.setCompanyName(e.target.value);
              setShouldRerender(Math.random());
            }}
            className="tabs-settings__input text-small"
            type="text" />
        </div>
      </EditField>
      {/* </EditFieldWrapper> */}
    </ItemWrapper>
  )
})

const mapStateToProps = state => {
  return {
    emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    emailsOpenedAmount: state.emailsOpenedAmount,
    movedMessages: state.movedMessages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentItem: payload => dispatch(setCurrentItem(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignItem)

