import React, { useState, useEffect, memo } from 'react'
import styled, { css } from 'styled-components'

const GlobalWrapper = styled.div`
     ${({ flex }) => flex && css`
        display: flex;
        flex-direction: ${({ flexColumn }) => flexColumn ? 'column' : 'initial'};   
        justify-content: space-evenly;
        align-items: flex-start;
        flex - wrap: ${ ({ flexWrap }) => flexWrap ? 'wrap' : 'initial'};
`}
    ${({ center }) => center && css`
        justify-content: center;
        align-items: center;
    `}
    white-space:nowrap;
`;

const StickySliderWrapper = styled(GlobalWrapper)`
    position: relative;
    height: ${({ height }) => height};   
    width: 600px;
    position: fixed;
    right: -2px;
    top: ${({ top }) => top};   
    z-index: 999;
    filter:drop-shadow(0px 0px 3px black);
    transition: 1s max-width ease-in-out;
    @media (max-width: 768px) {
        height: 180px;   
        width: 400px;
    }
    @media (max-width: 460px) {
        width: 220px;
    }
`;

const StyledStickySliderContent = styled(GlobalWrapper)`
    height:100%;
    width: 100%;
    background: #11C4D7;
    top: ${({ top }) => top};
    border-bottom-left-radius: 15px;
    padding-left: ${({ paddingLeft }) => paddingLeft};
    
    iframe{
        width: 90%;
        height: 90%;
        transition: 1s opacity ease-in-out;
        font-size: 10px;
    }
    
    @media (max-width: 768px) {
        padding-left: 35px;
        iframe{
        width: 100%;
        height: 90%;
    }
    }
    @media (max-width: 500px) {
        padding-left: 25px;
    }

`;

const StyledStickySliderDescription = styled.div`
    position: absolute;
    height: 75%;
    width: 40px;
    top: 0;
    left: -39px;
    font-weight: bold;
    background: #11C4D7;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: -10px 2px -2px 10px black;
    text-transform: uppercase;
    color: white;
    transform: rotate(180deg);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;

    :hover{
        cursor:pointer;
    }

    @media (max-width: 768px) {
        width: 40px;
        font-size: 10px;
    }
`;

const StyledStickySliderText = styled.div`
    transition: 1s opacity ease-in-out;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    
    @media (max-width: 768px) {
        font-size: 10px;
        white-space: pre-wrap;
    }
`;
const StyledStickySliderNumber = styled.div`
    transition: 1s opacity ease-in-out;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 22px;
    margin-top: -1px;

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const StickySliderBonusHelpWrapper = styled(GlobalWrapper)``;

const StyledStickySliderHelpText = styled.div`
    transition: 1s opacity ease-in-out;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    white-space: pre-wrap;
    max-width: 50%;
    font-weight: 300;

    @media (max-width: 768px) {
        font-size: 11px;
        max-width: 70%;
    }
`;

var setCookie = function (name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end === -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

const StickySlider = memo(() => {
    const [shouldSlideIn, setShouldSlideIn] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [shouldContentFadeIn, setShouldContentFadeIn] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, [])
    useEffect(() => {
        if (isMounted) {
            let user = getCookie("slider");
            if (user !== "") {
            } else {
                setTimeout(() => {
                    if (shouldSlideIn) {
                        setShouldContentFadeIn(false);
                        setTimeout(() => {
                            setShouldSlideIn(false);
                        }, 500);
                    } else {
                        setShouldSlideIn(true);
                        setTimeout(() => {
                            setShouldContentFadeIn(true);
                        }, 500);
                    }
                }, 20000);
                setCookie("slider", 4, 4);
            }
        }
    }, [isMounted])
    return (
        <>
            <StickySliderWrapper style={shouldSlideIn ? { maxWidth: '800px' } : { maxWidth: '0px' }} top="15%" height="240px">
                <StyledStickySliderContent flex flexColumn paddingLeft="35px">
                    <StickySliderBonusHelpWrapper flex flexColumn>
                        <StyledStickySliderText style={shouldContentFadeIn ? { opacity: '1' } : { opacity: '0' }}>Zadzwoń</StyledStickySliderText>
                        <StyledStickySliderNumber style={shouldContentFadeIn ? { opacity: '1' } : { opacity: '0' }}>+48 530 18 18 19 </StyledStickySliderNumber>
                    </StickySliderBonusHelpWrapper>
                    <StyledStickySliderHelpText style={shouldContentFadeIn ? { opacity: '1' } : { opacity: '0' }}>Nasz konsultant pomoże Tobie skutecznie wyszukać leady B2B oraz wysłać do nich efektywną kampanię e-mailingową</StyledStickySliderHelpText>
                </StyledStickySliderContent>
                <StyledStickySliderDescription onClick={() => {
                    if (shouldSlideIn) {
                        setShouldContentFadeIn(false);
                        setTimeout(() => {
                            setShouldSlideIn(false);
                        }, 500);
                    } else {
                        setShouldSlideIn(true);
                        setTimeout(() => {
                            setShouldContentFadeIn(true);
                        }, 500);
                    }
                }}>Pomoc</StyledStickySliderDescription>
            </StickySliderWrapper>
        </>
    )
})

export default StickySlider