export const saleURL = 'https://zamow.contact-hunter.com/';

export const textModalSale = `
<div class="modal-container">
<p class="text-medium text-center text-height boldText">
  Przekroczono liczbę zapytań dla tego pakietu.
</p>
<p class="text-medium text-center text-height thickText">
  Opcja dostępna tylko dla użytkownikow konta premium
</p>
<p class="text-big text-center text-height-medium mediumText">
  korzystaj ze wszystkich funkcji <br />
  bez ograniczeń teraz -10% taniej <br />
  <span>twój kod rabatowy:</span><br />
  XSHUNTER43287
</p>
<p class="text-medium text-center text-height smallText">
  wytrop zweryfikowane adresy e-mail szukanych osób <br />
  znajdź adresy e-mail firm <br />
  buduj złote bazy e-mailingowe <br />
  wysyłaj spersonalizowane wiadomości do leadów
</p>
</div>
`
