import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;

const ClearButtonTopLayerWrapper = styled.div`
 position: absolute;
  left: 10px;
  top: 15px;
  background: linear-gradient(to right, #12c4d8 0%, #68e5f0 100%);
  border: none;
  padding: 10px 15px 10px 15px;
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  opacity: 0;
  @media (max-width: 1200px) {
     display: none;
     background: red;
  }
  ${props => {
        if (props.fade) {
            return css`
            animation: ${fadeIn} 0.7s ease-in-out both;
          `;
        }
    }}
`;

const ClearButtonTopLayer = ({ onClick }) => {
    const [shouldFade, setShouldFade] = useState(false);
    useEffect(() => {
        setShouldFade(true)
    }, [])
    return (
        <ClearButtonTopLayerWrapper fade={shouldFade} onClick={onClick} className="deleteButtonTopLayer">
            Usuń wszystkie adresy
        </ClearButtonTopLayerWrapper>
    )
}

export default ClearButtonTopLayer