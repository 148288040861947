import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;

const ClearButtonHeaderWrapper = styled.div`
position: absolute;
  left: 0px;
  top: -40px;
  background: linear-gradient(to right, #12c4d8 0%, #68e5f0 100%);
  border: none;
  padding: 10px 15px 10px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  display: none;
  cursor: pointer;
  opacity: 0;
  font-size: 12px;
  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 768px) {
    font-size: 8px;
    top: -45px;
    padding: 10px 9px 10px 9px;
  }
  ${props => {
    if (props.fade) {
      return css`
            animation: ${fadeIn} 0.7s ease-in-out both;
          `;
    }
  }}
`;

const ClearButtonHeader = ({ onClick }) => {
  const [shouldFade, setShouldFade] = useState(false);
  useEffect(() => {
    setShouldFade(true)
  }, [])
  return (
    <ClearButtonHeaderWrapper fade={shouldFade} onClick={onClick} className="deleteButtonHeader">
      Usuń wszystkie adresy
        </ClearButtonHeaderWrapper>
  )
}

export default ClearButtonHeader