
import React, { useEffect, useRef, useState, memo } from 'react'
import $ from 'jquery'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { setTip, setHref, setEmailsOpenedAmount, setEmailsWantedToSendAmount, setEmailsSuccesfullySentAmount, setProgressBarValue } from '../../redux/reducer'

import logo from './../../assets/img/logo.png';
import logoUser from './../../assets/img/logowanie.png';
import ClearButtonHeader from './ClearButtonHeader'

import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from '../ProgressBar/ProgressBar'
import axios from 'axios';

const ClearButton = styled.div`
  position: absolute;
  left: 0px;
  top: -40px;
  background: linear-gradient(to right, #12c4d8 0%, #68e5f0 100%);
  border: none;
  padding: 10px 15px 10px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  display: none;
  cursor: pointer;
  font-size: 12px;
  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 768px) {
    font-size: 8px;
    top: -45px;
    padding: 10px 9px 10px 9px;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 768px) {
    ${({ isLogged }) => isLogged ? css`
      left: 60% !important;
    ` : css`
      left: 75% !important;
    `}  
  }
`;

const Header = memo((props) => {

  const prevState = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProgress = prevState(props.progressBarValue);

  useEffect(() => {
    if (props.emailsSuccesfullySentAmount === 1 && props.emailsOpenedAmount <= 0 && props.layerIsShowTop) {
      $('.progressBar').fadeIn();
      $('.nav-header__btn-send_sendmail').attr('disabled', null);
    }
    // if (props.emailsOpenedAmount <= 0) {
    //   props.setProgressBarValue(Math.round((props.emailsSuccesfullySentAmount / props.emailsWantedToSendAmount) * 100));
    // }
  }, [props.emailsSuccesfullySentAmount])

  useEffect(() => {
    // let promise = new Promise((resolve, reject) => {
    //   if (props.emailsOpenedAmount > 0) {
    //     resolve(true);
    //   }
    // })
    // promise.then(canProgressChange => {
    //   if (canProgressChange) {
    //     props.setProgressBarValue(Math.round((props.emailsOpenedAmount / props.emailsWantedToSendAmount) * 100));
    //   }
    // })

    // if (props.emailsSuccesfullySentAmount === props.emailsWantedToSendAmount && props.emailsSuccesfullySentAmount > 0) {
    //   props.setProgressBarValue(Math.round((props.emailsOpenedAmount / props.emailsWantedToSendAmount) * 100));
    //   console.log('set here progressbar amount OPENED AMOUNT')
    // } else {
    //   props.setProgressBarValue(Math.round((props.emailsSuccesfullySentAmount / props.emailsWantedToSendAmount) * 100));
    // }

  }, [props.emailsOpenedAmount, props.emailsSuccesfullySentAmount])

  return (
    <div className="nav-header">
      {props.campaignItems.length > 0 && <ClearButtonHeader onClick={props.showDeleteModal}>Usuń wszystkie adresy</ClearButtonHeader>}
      <div className="nav-header__btn-wrapper">
        {!props.layerIsShowTop ? (
          <button
            className="nav-header__btn nav-header__btn--left text-left"
            onClick={() => {
              if (props.isLogged) {
                props.showLayerTop();
                props.setTip('Jak skonfigurować i wysyłać kampanie e-mailingowe?');
                props.setHref('https://zamow.contact-hunter.com/jak-skonfigurowac-i-wysylac-kampanie-e-mailingowe/');
                if (props.emailsSuccesfullySentAmount > 0 || props.emailsOpenedAmount > 0 || props.emailsWantedToSendAmount > 0) {
                  $('.progressBar').fadeIn();
                }
              } else {
                props.handleShowCustomModal(`
                <div class="modal-container">
                <p class="text-medium text-center text-height boldText">
                  KUP KONTO PREMIUM 
                </p>
                <p class="text-medium text-center text-height smallText">
                - TWÓRZ PROFESJONALNE KAMPANIE E-MAILINGOWE. <br />
                - PLANUJ ICH CZAS ROZPOCZĘCIA. <br />
                - WYSZUKUJ BAZY ADRESOWE, IMPORTUJ I EKSPORTUJ KONTAKTY. <br />
                - ŚLEDŹ STATUSY DORĘCZEŃ WIADOMOŚCI. <br />
                - OSZUKAJ FILTRY ANTYSPAMOWE. <br />
                </p>
                <p class="text-medium text-center text-height thickText">
                  WSZYSTKO CZEGO POTRZEBUJESZ TO FIRMOWA SKRZYNKA LUB ZWYKŁE KONTO GMAIL.
                </p>
                </div>
                `)
              }
            }}
          >
            <p className="text-medium">Rozpocznij kampanie e-mail</p>
          </button>
        ) : props.shouldImportExportAppear ? (
          <button
            className="nav-header__btn nav-header__btn--left text-left"
            onClick={props.toggleImportExport}
          >
            <span className="text-medium">Eksport</span>
            <span className="text-medium"> / </span>
            <span className="text-medium"> Import</span>
          </button >
        ) : null
        }

        {props.isLogged && (props.emailsOpenedAmount > 0 && (props.emailsSuccesfullySentAmount === props.emailsWantedToSendAmount && props.emailsWantedToSendAmount > 0)) &&
          <div className="text-center">
            <p className="nav-header__info text-light">Odczytano e-maili</p>
            <p className="text-big text-big text-main-color ">{props.emailsOpenedAmount}/{props.emailsWantedToSendAmount}</p>
          </div>
        }

        {props.isLogged && props.emailsSuccesfullySentAmount > 0 && props.emailsOpenedAmount <= 0 &&
          <div className="text-center">
            <p className="nav-header__info text-light">Wysłano e-maili</p>
            <p className="text-big text-big text-main-color "> {props.emailsSuccesfullySentAmount}/{props.emailsWantedToSendAmount}</p>
          </div>
        }

        {props.isLogged && props.emailsWantedToSendAmount >= 0 && props.emailsOpenedAmount <= 0 && props.emailsSuccesfullySentAmount <= 0 &&
          <div className="text-center">
            <p className="nav-header__info text-light">Dodano e-maili</p>
            <p className="text-big text-big text-main-color "> {props.emailsWantedToSendAmount}/{props.maxEmailsAmount}</p>
          </div>
        }

      </div>
      <ProgressBar progress={props.progressBarValue} prevProgress={prevProgress} />
      {
        (props.emailsSuccesfullySentAmount > 0 || props.emailsOpenedAmount > 0 || props.movedMessages > 0) ?
          <Wrapper isLogged={props.isLogged} className="nav-header__logo-wrapper">
            <div className="nav-header__logo-inner">
              <button
                disabled={props.emailsSuccesfullySentAmount > 0 || props.emailsOpenedAmount > 0 || props.movedMessages > 0 ? null : true}
                onClick={() => {
                  props.createNewCampaign();
                  props.clearCampaign();
                  props.turnOffLayer();
                }}
                className="nav-header__btn-send nav-header__btn-send_sendmail smallerFont"
                type="button">Utwórz nową kampanię</button>
              <img className="nav-header__logo" src={logo} alt='' />
            </div>
          </Wrapper> :
          <Wrapper className="nav-header__logo-wrapper" isLogged={props.isLogged}>
            <div className="nav-header__logo-inner">
              <button
                onClick={props.handleSendMessage}
                className="nav-header__btn-send nav-header__btn-send_sendmail"
                type="button"
                disabled={props.emailsWantedToSendAmount <= 0 ? true : null}>
                Wyślij
              </button>
              <img className="nav-header__logo" src={logo} alt='' />
            </div>
          </Wrapper>
      }
      {
        props.emailsWantedToSend > 0 ?
          <button
            onClick={props.createNewCampaign}
            className="nav-header__btn-send--mobile smallerFont"
            type="button">Utwórz nową kampanię
      </button> :
          <button
            onClick={props.handleSendMessage}
            className="nav-header__btn-send--mobile "
            type="button">Wyślij
  </button>
      }
      {
        props.isLoggedIn ? (
          <div onClick={() => {
            props.showLayerBottom();
            $('.progressBar').fadeOut();
            props.updateCharts();
          }} className="nav-header__user-wrapper text-right">
            <div className="credits">
              <p>{props.user}</p>
            </div>
            <img className="nav-header__user-wrapper__image" src={logoUser} alt="" />
          </div>
        ) : (
            <div className="nav-header__login-wrapper">
              {!props.layerIsShowTop &&
                <button
                  onClick={props.showLayerBottom}
                  className="nav-header__btn nav-header__btn--right text-left  text-medium"
                >Zaloguj
                </button>
              }
            </div>
          )
      }
    </div >
  )
})

const mapStateToProps = state => {
  return {
    tip: state.tip,
    href: state.href,
    emailsOpenedAmount: state.emailsOpenedAmount,
    emailsWantedToSendAmount: state.emailsWantedToSendAmount,
    emailsSuccesfullySentAmount: state.emailsSuccesfullySentAmount,
    progressBarValue: state.progressBarValue,
    maxEmailsAmount: state.maxEmailsAmount,
    movedMessages: state.movedMessages
  }
}

const mapDisptachToProps = dispatch => {
  return {
    setTip: payload => dispatch(setTip(payload)),
    setHref: payload => dispatch(setHref(payload)),
    setEmailsOpenedAmount: payload => dispatch(setEmailsOpenedAmount(payload)),
    setEmailsWantedToSendAmount: payload => dispatch(setEmailsWantedToSendAmount(payload)),
    setEmailsSuccesfullySentAmount: payload => dispatch(setEmailsSuccesfullySentAmount(payload)),
    setProgressBarValue: payload => dispatch(setProgressBarValue(payload))
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(Header)
